.manage-source-domains {
  .manage-source-label {
    font-weight: bold;
  }

  .manage-source-domains {
    display: flex;
    align-items: center;

    .manage-source-domain {
      margin-top: 1rem;
      width: 20rem;
      height: 30px;
      margin-bottom: 0;
    }
  }

  .rectangular-button {
    padding-left: 0;
    margin-top: 1rem;
  }
}
