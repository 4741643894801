[tooltip] {
  //margin: 20px 5px;
  position: relative;
  display: inline-block;
  font-weight: 400;
}
[tooltip]::before {
  content: '';
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0 6px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
  z-index: 1001;
  opacity: 0;
}

[tooltip-position='left']::before {
  left: 0%;
  top: 50%;
  margin-left: -13px;
  transform: translatey(-50%) rotate(-90deg);
}

[tooltip-position='top']::before {
  left: 50%;
}

[tooltip-position='bottom']::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg);
}

[tooltip-position='right']::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translatey(-50%) rotate(90deg);
}

[tooltip]::after {
  content: attr(tooltip);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  color: #fff;
  padding: 4px 2px;
  font-size: 12px;
  min-width: 80px;
  //border-radius: 5px;
  pointer-events: none;
  padding: 4px 4px;
  z-index: 1001;
  opacity: 0;
  display: block !important;
  // width: var(--width);
  // width: 0;
  // height: 0;
}

[tooltip-position='left']::after {
  left: 0%;
  top: 50%;
  margin-left: -8px;
  transform: translateX(-100%) translateY(-50%);
}

[tooltip-position='top']::after {
  left: 50%;
}

[tooltip-position='bottom']::after {
  border-radius: 2px;
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%) !important;
  width: auto !important;
}

[tooltip-position='right']::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%);
}

[tooltip]:hover::after,
[tooltip]:hover::before {
  opacity: 1;
  transition-delay: 0.5s !important;
  //transition: all 1.0s;
  display: block !important;
  width: var(--width);
  height: auto;
}

[tooltip-position='rightbottom']::after {
  border-radius: 2px;
  top: 100% !important;
  margin-top: 8px;
  transform: translateX(calc(-100% + 20px)) translateY(0%) !important;
  width: auto !important;
}

[tooltip-position='rightbottom']::before {
  top: 100% !important;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg) !important;
}
