@import 'css/_variables.scss';

.popover {
  border-radius: 4px !important;
  padding: 5px !important;
  max-width: 450px !important;
  min-width: 350px !important;
  border: 1px solid $gray-2 !important;
  .popover-content {
    .popover-data {
      .popover-main-title {
        color: $nickel;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
      }
      .popover-main-description {
        color: $gray-4;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
      }
      .popover-action-link {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: $av-blue;
      }
      .popover-icon {
        color: $av-blue;
      }
      .close-btn {
        background: none;
        border: none;
        outline: none;
        padding: 0;
      }
      .close-icon {
        color: $gray-3;
      }
      .react-player {
        iframe {
          width: auto !important;
          height: auto !important;
          padding: 0px;
        }
      }
    }
  }
}
