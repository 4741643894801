@import 'css/_variables.scss';

.manage-goals-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .body {
      background: $gray-1;
      border: 1px solid $gray-2;
      border-radius: 0.5rem;
      margin: 1rem 0 2rem;
      padding: 1rem 2rem;
    }

    .goal-select {
      flex-grow: 1;
      margin-right: 1rem;
    }

    .goal-add-button {
      flex-shrink: 0;
    }

    .activity {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .count {
        font-weight: bold;
        flex-shrink: 0;
        padding: 10px 24px;
        border: 1px solid #c4ddf1;
        border-radius: 4px;
        // width: 72px;
        text-align: center;
      }

      .icon {
        color: $av-blue;
        margin-right: 12px;
        margin-left: 12px;
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
    }

    .goal-number-input {
      width: 4rem;
      border: 0 !important;
      outline: none !important;
      background-color: transparent;
      text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input.goal-number-input::-webkit-outer-spin-button,
    input.goal-number-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input.goal-number-input[type=number] {
      -moz-appearance: textfield;
    }
  }
}