@import 'css/_variables.scss';

.multiple-profile-images {
  .profile-image {
    width: 24px !important;
    height: 24px !important;
  }

  > span:not(:first-child),
  .count {
    margin-left: -5px;
  }

  .invite-dropdown {
    .dropdown-toggle {
      padding: 0 !important;
      border: none !important;
      background: transparent !important;
      color: $av-grayish-cyan;
      box-shadow: none;
      height: 25px;

      &:hover {
        color: $av-blue;
      }
    }
  }

  .count {
    height: 24px;
    width: 24px;
    text-align: center;
    border: 1px solid $av-grayish-cyan;
    border-radius: 24px;
  }

  .dropdown-menu {
    width: 260px;
    padding: 0;

    a[role='menuitem'] {
      margin: 16px 16px;
      padding: 0;

      &:hover {
        background: transparent !important;
      }
    }

    .header {
      border-bottom: 1px solid $av-grayish-cyan;
    }

    .invited,
    .unregistered {
      opacity: 0.3;
    }
  }
}
