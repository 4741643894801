@import 'css/_variables.scss';

.sync-auto-complete {
  position: relative;
  width: 100%;

  .floating-input {
    z-index: 99;
  }

  .sync-auto-complete-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 98;
  }

  .options-container {
    position: absolute;
    top: 100%;
    background-color: $gray-1;
    width: 100%;
    font-weight: 500;
    box-shadow: 0px 2px 8px 1px $gray-2;
    z-index: 100;
    max-height: 200px;
    overflow: auto;

    .option {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid $gray-2;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $gray-2;
      }

      &.disabled {
        color: $gray-3;
      }

      &.disabled:hover {
        background-color: $gray-1;
      }
    }
  }

  .spinner {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}
