@import 'css/_variables.scss';

.four-pack {
  button.tab {
    color: $gray-3;
    box-shadow: none !important;
    justify-content: center;
    margin-right: 12px;

    &:hover {
      color: $av-blue;
    }

    &.active {
      color: $av-blue;
    }

    &:disabled {
      opacity: 0.3;
    }
  }
}
