@import '../../../../../css/variables';

.point-card-details {
  padding: 10px 20px 0px 20px;

  .point-text {
    // font-size: 16px;
    // letter-spacing: 0.1px;
    color: #333333;
    //line-height: 1.5;
    margin: 0 0 0px;
    overflow-wrap: break-word;
  }

  .point-source-text {
    font-size: 14px;
    font-style: italic;
    color: #333333;
    letter-spacing: 0.1px;
    margin: 5px 15px 5px 15px;
    overflow-wrap: break-word;
  }

  .point-source {
    border-radius: 10px;
    // border: solid 1px #b7c7d0;
    font-size: 12px;
    margin-top: 0;
    padding: 5px 20px;
    //border: 1px solid red;
    color: #333333;

    .source-quote {
      //font-style: italic;
      // max-height: 250px;
      // overflow-y: scroll;
      // overflow-wrap: break-word;
      // padding: 5px 15px;
    }

    .citation {
      // margin-left: 15px;
      // // font-weight: 400;
      // // font-size: 12px;
      // time {
      //   //font-weight: 600;
      //   line-height: 1.21;
      //   text-align: left;
      //   color: #b7c7d0;
      // }
    }
  }

  .point-tags {
    display: inline-block;
    //border: 1px solid red;
  }

  .tags-container {
    padding-top: 30px;
  }

  .tags-container {
    padding-top: 10px;

    .react-tags__search {
      display: inline-table;
    }
  }
  /* mobile  */
  @media (max-width: 768px) {
    .point-text {
      margin-bottom: 10px;
    }

    .point-source {
      font-size: 12px;
      padding: 15px;
      //font-style: italic;

      .point-source-details {
        //align-items: center;
        //display: flex;
        //justify-content: left;
      }
    }
  }
}
