@import 'css/_variables.scss';

.survey-component {
  .question-title {
    color: $av-blue;
    padding-right: 30px;
    font-weight: bold;
  }

  .question-sub-title {
    color: $av-blue;
    // font-weight: bold;
    margin-top: 10px;
  }

  .av-checkbox {
    color: $av-blue;
    display: flex;
    align-items: center;

    .fake-checkbox {
      flex-shrink: 0;
    }
  }

  .select-points-question {
    .create-point-light-weight {
      padding-bottom: 8px;
      padding-top: 4px;
      position: relative;

      .import-point-link {
        right: 32px;
        top: 1px;
        left: auto;
      }

      .create-point-entry-form {
        .lightweight-stance {
          display: none;
        }

        .point-and-quote {
          padding-bottom: 10px !important;
          padding-top: 30px !important;
        }

        .point-edit-controls {
          margin-bottom: 44px;
        }

        .btn-submit {
          bottom: -69px;
        }
      }
    }

    .toggle-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      border: 1px solid $av-blue;
      color: $av-blue;
      font-size: 12px;
      // height: 30px;
      background-color: transparent;

      &.active {
        background-color: $av-blue;
        color: #fff;
      }
    }

    .point-card {
      &.top-evidence {
        margin-top: 0;
      }
    }

    .question-title {
      display: inline;
    }

    .btn-provide-evidence {
      cursor: pointer;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .import-import {
    background-color: $av-light-blue;
  }
}

.single-choice-button {
  .rectangular-button {
    border: 1px solid $av-blue;
    min-width: 100px;

    &.active {
      background-color: $av-blue;
      color: #fff;
    }

    .content {
      height: 100%;
      justify-content: center;
    }
  }
}

.info-question {
  .carousel-section {
    .carousel-indicators {
      bottom: -40px;

      li {
        background-color: $av-light-blue;
        height: 12px;
        width: 12px;

        &.active {
          background-color: $av-blue;
        }
      }
    }

    .carousel-control {
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.13);
      border-radius: 75px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.left {
        left: 24px;
        top: calc(50% - 20px);
      }

      &.right {
        right: 24px;
        top: calc(50% - 20px);
      }
    }
  }
}

.counter-group {
  .icon {
    color: $av-blue;
    margin-right: 12px;
    margin-left: 12px;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .count {
    font-weight: bold;
    flex-shrink: 0;
    padding: 10px 24px;
    border: 1px solid #c4ddf1;
    border-radius: 4px;
  }
}

.source-picker-toggle {
  color: $av-blue;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;

  .source-picker-icon {
    margin-right: 10px;
  }
}

.source-input-container {
  display: flex;
  align-items: center;

  div:nth-child(1) {
    flex-grow: 1;
    margin-bottom: 0;
  }

  .button {
    align-self: flex-end;
    margin-left: 12px;
  }
}

.hintForNpr {
  color: $av-blue;
  margin-top: 5px;
}

.user-selected-location {
  color: $av-blue;
  padding: 0.5rem 0 0;
  font-weight: 600;
}