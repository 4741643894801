@import 'css/_variables.scss';

.collection-card {
  background-color: $av-white;
  border: 1px solid $gray-2;
  border-radius: 8px;
  cursor: pointer;
  // padding-bottom: 16px;

  .header {
    background-color: $gray-1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .body {
    .error {
      display: flex;
      justify-content: flex-end;
      color: $av-red;
      font-size: 10px;
    }

    .source-name {
      font-weight: 700;
      font-size: 12px;

      &.video {
        color: $av-accent-pink;
      }

      &.text {
        color: $av-accent-purple;
      }
    }

    .stats {
      color: $av-blue;
      margin-right: 15px;
    }

    .divider {
      margin-top: 15px;
    }

    .actions {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 15px 0;

      .btn {
        border-width: 0;
        color: #b7c6cf;
        font-weight: 600;
        padding: 0px;

        &:hover {
          color: $av-blue;
          text-decoration: none;
        }

        .icon {
          margin-right: 5px;
        }

        .icon-share {
          height: 12px;
          width: 11px;
        }
      }

      .share-btn {
        margin-left: auto;
      }
    }

    .play-icon {
      background-image: url('../../../assets/images/play.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: 28px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: #e6e6e6;
    }
  }

  .card-overlay {
    color: #0470ff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
  }

  &.collection-card--highlighted {
    border-color: $av-red;
  }

  .author-container {
    border-top: 1px solid #eaeaea;
  }

  .donate-section {
    // border-top: 1px solid $av-light-gray;

    .icon-link {
      background: $av-blue;
      color: $av-white;
      border-radius: 100%;
    }
  }

  .group-container {
    // margin: 5px 16px 0 16px;
    background-color: $gray-3;
    border-radius: 4px;
    // border-bottom-left-radius: 8px;
    // border-bottom-right-radius: 8px;
  }

  .donate-section {
    // margin: 13px 16px 0 16px !important;
    background-color: $gray-3;
    border-radius: 8px;
    background-color: $av-light-blue;
    text-decoration: none;
  }

  .donate-section:hover {
    background-color: $av-dark-blue;
  }

  .group-container:hover {
    background-color: $av-dark-gray;
  }

  .collection-header-controls {
    .hover-action {
      visibility: hidden;
      opacity: 0;
      transition: visibility 300ms linear 0ms, opacity 300ms linear 0ms;
    }

    .btn-container {
      .btn {
        font-size: 12px;
        border-radius: 25px;
        min-width: 70px;
      }
    }
  }

  &:hover {
    .collection-header-controls {
      .hover-action {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 300ms, opacity 300ms linear 300ms;
      }
    }

    .title {
      color: $av-blue;
    }
  }
}
