@import "css/_variables.scss";

#drop-down-container{
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
}

#drop-down-component{
    position: absolute;
    top: 0;
    right: 0;
    //left: 0;
    width: 100%;
    height: 100%;
}

.icon-container{
    margin-top: -10px;
    font-size: 40px;
    height: 35px;
    display: inline-block;
    cursor: pointer;
    //color: #b7c6cf;
    .icon{
      height: 25px;
      width: 25px;
    }
}

.menu{
    position: relative;
    margin-top: 5px;
    margin-left: -110px;
    border-radius: 3px;
    background-color: $av-white;
    box-shadow: 10px 10px 20px 10px rgba(51, 51, 51, 0.1);
    height: 160px;
    width: 150px;
    z-index: 1000;
    .profile {
      padding: 5px 0px 5px 0px;
      border-bottom: 1px solid $av-grayish-cyan;
      background: $av-blue;
      color: white;
      .username{
        padding-left: 10px;
        font-size: 14px;
      }
    }
    .menu-item {
      //border-top: 1px solid #b7c7d0;
      cursor: pointer;
      padding: 5px 10px;
      &:hover{
          color: $av-blue;
          //background: #b7c7d0;
      }
    }
}

