@import 'css/_variables.scss';

.point-card {
  background-color: $av-white;
  border: 2px solid $av-light-blue;
  border-radius: 8px;
  padding: 0 16px 12px 16px;

  .donate-section,
  .four-pack,
  .point-details,
  .point-edit-controls,
  .point-header,
  .point-image-container,
  .point-text,
  .point-tags {
    margin-top: 10px;
  }

  .point-text {
    p {
      margin: 0;
      word-break: break-word;
      display: inline;
    }

    .point-image-inline {
      img {
        height: 18px;
        cursor: pointer;
      }

      .hover-preview {
        left: 0;
        bottom: calc(100% + 8px);
        box-shadow: 0px 8px 22px rgba(51, 51, 51, 0.1);
        border-radius: 4px;
        background-color: $av-white;
        z-index: 2;

        img {
          height: 185px;
        }
      }

      &:hover {
        .hover-preview {
          display: block !important;
        }
      }
    }
  }

  .donate-section {
    border-top: 1px solid $av-light-gray;
    margin-left: -16px;
    margin-right: -16px;
    padding: 12px 16px 0 16px;

    .icon-link {
      background: $av-blue;
      color: $av-white;
      border-radius: 100%;
    }
  }

  .author-container {
    border-top: 1px solid $av-light-gray;
  }

  &.list {
    .image-wrapper {
      text-align: left;
    }
  }

  .timestamp-warning {
    background: rgba($color: $av-gold, $alpha: 0.12);
    border: 1px solid $av-gold;
    border-radius: 4px;
    color: $av-gold;

    .icon-info {
      width: 25px;
    }

    .icon-close {
      cursor: pointer;
    }
  }

  .point-header {
    .timestamp {
      white-space: nowrap;
    }
  }

  &.list,
  &.default {
    .point-header {
      .hover-action {
        visibility: hidden;
        opacity: 0;
        transition: visibility 300ms linear 0ms, opacity 300ms linear 0ms;
      }

      .new-badge {
        visibility: visible;
        transition: visibility 0s linear 300ms, opacity 300ms linear 300ms;
      }
    }

    &:hover {
      .point-header {
        .hover-action {
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 300ms, opacity 300ms linear 300ms;
        }

        .new-badge {
          visibility: hidden;
        }
      }
    }
  }

  // Clip image beyound 300px
  .point-image-container {
    max-height: 300px;
    overflow: hidden;
  }

  .btn-submit {
    background: linear-gradient(
      288.08deg,
      #1286c8 0%,
      #2bb5e0 99.25%
    ) !important;
    border: none;
  }

  &.claim,
  &.evidence,
  &.top-evidence,
  &.questioned,
  &.reviewed {
    .total-sub-points,
    .author-container {
      display: none !important;
    }
  }

  &.claim,
  &.evidence,
  &.top-evidence {
    border: 2px solid $av-medium-blue;
  }

  &.claim {
    border-radius: 4px;
  }

  &.evidence,
  &.top-evidence,
  &.questioned,
  &.reviewed {
    border-radius: 0 0 4px 4px;
  }

  &.evidence,
  &.top-evidence {
    margin-top: -2px;
  }
}

.top-evidence-triangle,
.questioned-connector,
.reviewed-connector {
  background-color: $av-white;
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  top: -8px;

  div {
    background-color: $av-medium-blue;
    height: 16px;
    width: 2px;
  }
}

.top-evidence-triangle {
  left: 22px;
}

.questioned-connector {
  left: 128px;

  &.is-mobile {
    left: 78px;
  }
}

.reviewed-connector {
  left: 250px;

  &.is-mobile {
    left: 132px;
  }
}

.four-pack-tabs-connect {
  background-color: #c4ddf1;
  height: 15px;
  margin-left: 25px;
  width: 2px;

  &.EVIDENCE {
    margin-left: 27px;
  }

  &.QTP {
    margin-left: 27px;
  }

  &.REVIEW {
    margin-left: 150px;
  }

  &.COMMENT {
    margin-left: 250px;
  }

  &.is-mobile {
    &.EVIDENCE {
      margin-left: 27px;
    }

    &.QTP {
      margin-left: 27px;
    }

    &.REVIEW {
      margin-left: 75px;
    }
  }
}

.point-stack-container {
  .point-stack-line-1,
  .point-stack-line-2 {
    border: 2px solid $av-light-blue;
    border-radius: 0px 0px 4px 4px;
    height: 7px;
    margin-top: -2px;
  }

  .point-stack-line-1 {
    margin-right: 4px;
    margin-left: 4px;
  }

  .point-stack-line-2 {
    margin-right: 8px;
    margin-left: 8px;
  }

  .point-stack-sub-points-count {
    background-color: #519fde;
    border-radius: 3px;
    color: $av-white;
    font-size: 12px;
    font-weight: bold;
    left: 50%;
    padding: 2px 6px;
    top: -4px;
    transform: translateX(-50%);
  }
}
