@import 'css/_variables.scss';

.evidence-filter {
  .against {
    color: $av-red;
    border-color: $av-red;

    &:hover {
      border-color: $av-red;
      background: $av-red;
    }
  }

  .for {
    color: $av-green;
    border-color: $av-green;

    &:hover {
      border-color: $av-green;
      background: $av-green;
    }
  }

  .neutral {
    color: $av-grayish-cyan;
    border-color: $av-grayish-cyan;

    &:hover {
      border-color: $av-grayish-cyan;
      background: $av-grayish-cyan;
    }
  }

  .btn-container {
    .btn {
      padding: 5px 11px;
    }
  }
}
