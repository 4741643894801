@import 'css/_variables.scss';

.textarea-autosize {
  border: none;
  outline: none;
  background-color: transparent;

  &::placeholder {
    color: $av-grayish-cyan;
  }
}
