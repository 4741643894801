@import 'css/_variables.scss';

.stp-card {
  background-color: $av-white;
  border: 2px solid $av-light-blue;
  border-radius: 4px;

  .stp-text {
    margin-top: 5px;
    font-style: italic;
  }
}
