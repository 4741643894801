.modal {
  z-index: 1070 !important;
}

.modal-backdrop {
  background-color: #f4f4f4;
  backdrop-filter: blur(3px);
  &.in {
    opacity: 0.2;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 760px;
  }
}

.modal-content {
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);
}
.modal-header {
  border-bottom: 1px solid #ccdae2;
  padding: 17px 0;
  .btn-close {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .icon-close {
    color: #b7c7d0;
    margin: 0;
  }
  .modal-title {
    color: #1286c8;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.21;
    text-align: center;
  }
}
.modal-body {
  padding: 20px;
}

.modal-footer {
  border-top-width: 0;
  text-align: center;
  .btn {
    display: block;
    margin: 0 auto 10px;
    //width: 220px;
  }
}
