.action-link {
  //position: relative;
  display: inline-block;
  border-width: 0;
  // color: #b7c6cf;
  font-weight: 400;
  padding: 3px 2px;
  cursor: pointer;
  &:hover,
  &:focus,
  &.active,
  &:active,
  &:active:focus,
  &.active:focus {
    box-shadow: none;
    color: #1286c8;
    outline: none;
    text-decoration: none;
  }
  &.disabled {
    // color: #b7c6cf !important;
    opacity: 0.65;
    cursor: not-allowed;
  }

  .action-link-text {
    white-space: nowrap;
  }
}
