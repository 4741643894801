.no-review-message {
    padding: 10px;
}

.messenger-section{

    .message-heading {
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        padding: 5px 10px;
        color: #333333;
      }

      .message-list {
        max-height: 200px;
        overflow-y: auto;
        border-top: 1px solid #B7C6CF;
        border-bottom: 1px solid #B7C6CF;
        padding-left: 0;
      }

      .review-send {
        padding-left: 0;
        border-top: 1px solid #B7C6CF;
      }



      .your-message {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 0;
        width: 350px;
        .sent-message {
          border-radius: 10px;
          background-color: #B7C6CF;
          color: #FFFFFF;
          font-size: 10px;
          padding: 10px;
          margin-left: 5px;
          width: 270px;
          @media screen and (max-width: 767px){
            width: 250px;
          }
          .message-label {
            font-style: italic;
            padding: 7px 0;
            border-bottom: 2px solid #c8d4db;
          }
          .message-body {
            padding-top: 9px;
          }
        }
        .user-profile-image {
          position: absolute;
          bottom: 2px;
          right: 1px;
          @media screen and (max-width: 767px){
            right: 20px;
          }
        }
      }

      .other-message {
        position: relative;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 350px;
        .user-profile-image {
          bottom: 0;
          position: absolute;
          left: 10px;
          @media screen and (max-width: 767px){
            left: 10px;
          }
        }
        .sent-message {
          //right: 30px;
          border-radius: 10px;
          background-color: rgba(51, 51, 51, 0.109804);
          color: #000000;
          font-size: 10px;
          padding: 10px;
          margin-left: 60px;
          @media screen and (max-width: 767px){
            margin-left: 60px;
          }
          // float: right;
          width: 270px;
          @media screen and (max-width: 767px){
            width: 250px;
          }
          .message-label {
            font-style: italic;
            padding: 7px 0;
            border-bottom: 2px solid #c8d4db;
          }
          .message-body {
            padding-top: 9px;
          }
        }
      }



      .message {
        padding: 15px 10px;
        border-bottom: 2px solid #B7C6CF;
        margin-bottom: 10px;
        position: relative;
        textarea {
          border: none;
          -webkit-box-shadow: none;
          box-shadow: none;
          padding: 0;
          resize: none;
          font-family: 'Open Sans';
          font-size: 12px;
          line-height: 1.5;
          text-align: left;
          color: #333333;
          overflow-y: auto;
          width: 75%;
          display: inline-block;
        }
        .from-name {
          color: #0085CB;
          position: absolute;
          left: 137px;
          font-size: 13px;
          font-weight: bold;
          cursor: pointer;
          top: 13px;
        }
        .fa-paperclip {
          color: #0085CB;
          position: absolute;
          font-size: 14px;
          cursor: pointer;
          top: 15px;
          display: inline-block;
          right:70px;
        }
      }

      .message-input {
        .list-unstyled {
          padding-left: 25px;
        }
      }
}
