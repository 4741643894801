.form-group {
  margin-bottom: 20px;
    @media screen and (max-width: 767px){
      margin-bottom: 10px;
    }
}
.form-control {
  color: #333;
  &[type='text'] {
    border-color: #b7c7d0;
    border-radius: 0;
    border-width: 0 0 2px;
    box-shadow: none;
    padding: 10px;
  }
  &::placeholder {
    color: $chrome;
  }
}
