@import 'css/_variables.scss';

.manage-readings-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .goal-select {
      flex-grow: 1;
      margin-right: 1rem;
    }

    .goal-add-button {
      flex-shrink: 0;
    }

    .activity {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .count {
        font-weight: bold;
        flex-shrink: 0;
        padding: 10px 24px;
        border: 1px solid #c4ddf1;
        border-radius: 4px;
      }

      .activity-text {
        color: $av-blue;
      }

      .icon {
        color: $av-blue;
        margin-right: 12px;
        margin-left: 12px;
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
      }
    }

    .manage-readings-component {
      .title {
        color: $av-gray;
      }

      .reading-url-edit {
        position: relative;
        flex-grow: 1;

        .loader {
          position: absolute;
          right: 1rem;
          top: 2rem;
        }

        .lookup-spinner {
          right: 0;
          top: 25px;
        }

        .lookup-button {
          right: 0;
          top: 16px;
          padding: 3px;
          font-size: 14px;
        }
      }

      .source-logo {
        width: 16px;
        height: 16px;
      }

      .icon {
        cursor: pointer;
      }

      .readings {
        .reading-title {
          font-size: 1.4rem;
        }

        .reading-url {
          font-size: 1.2rem;
          color: $gray-4;
        }

        .icon {
          margin-top: 0.6rem;
        }
      }
    }

    .readings-container {
      td {
        padding: 0.5rem 0;
      }

      .reading-status-column {
        vertical-align: top;
        white-space: nowrap;
      }

      .readings {
        // display: flex;
        align-items: flex-start;
        width: 100%;

        .source-logo {
          flex-shrink: 0;
          margin-top: 0.25rem;
        }

        .reading-title {
          flex-grow: 1;

          .reading-url {
            color: $gray-4;
          }

          .reading-error-message {
            font-size: 1.2rem;
          }
        }
      }

      .create-quiz-column {
        white-space: nowrap;
        text-align: center;
      }

      .reading-status {
        flex-shrink: 0;
        display: inline;

        .check,
        .add-icon {
          background-color: $av-light-blue !important;
          border-radius: 10px;
          height: 20px;
          // margin-right: 12px;
          width: 20px;
          flex-shrink: 0;
          margin-top: 4px;

          .icon {
            margin-top: 0;
          }

          &.success {
            background-color: $av-green !important;
          }

          &.error {
            background-color: $av-red !important;
          }
        }

        .add-icon {
          background-color: $av-blue !important;
        }
      }
    }

    .font-size-small {
      font-size: 12px;
    }

    .radio-button {
      width: 16px;
      height: 16px;
      margin-right: 10px !important;
      margin-top: 0;
    }

    .editor {
      // border-bottom: 1px solid $av-grayish-cyan;
      min-height: 200px;
      padding-bottom: 10px;
      margin-top: 15px;

      .custom-toolbar {
        visibility: visible !important;
        position: sticky;
        top: 3px;
        z-index: 2;
      }
    }

    .input-author {
      flex-wrap: wrap;
      margin-top: 10px;

      .author {
        background-color: $av-blue;
        display: inline-block;
        color: $av-white;
        border-radius: 5px;
        padding: 5px 10px;
        margin-right: 10px;
        font-size: 12px;
      }

      .add-author {
        display: inline-block;
        height: 50px;
        width: 200px;
      }
    }

    .status-check {
      width: 14px;
      height: 14px;
      padding: 1px;
      border-radius: 3px;
      border: 1px solid $av-white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.25rem;

      &.yellow {
        background-color: $av-gold;
      }

      &.green {
        background-color: $av-green;
        color: $av-white;
      }

      &.red {
        background-color: $av-red;
        color: $av-white;
      }
    }

    .dropdown {
      margin: 0;

      .a-drop-down {
        margin-top: -10px;

        label {
          font-weight: 600;
          font-size: 10px;
        }
      }
    }

    .pdf-file-upload-section {
      .file-upload-container {
        cursor: pointer;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3rem 1rem;
        border: 1px dashed $av-blue;
        border-radius: 1rem;
        margin-top: 1rem;
        background-color: $av-light-gray;

        &:hover {
          background-color: $av-light-blue;
        }

        .icon {
          background-color: $av-blue;
          border-radius: 50%;
          color: white;
          padding: 0.25rem;
          width: 24px;
          height: 24px;
        }

        .supported-formats {
          font-size: 10px;
          margin-top: 0.25rem;
        }
      }

      .file-upload-title {
        margin-top: 0.25rem;
        font-weight: 600;
      }

      .or-divider {
        width: 50%;
        margin: 0.25rem 0 0;
        position: relative;
        text-align: center;
        color: $gray-2;

        &::before,
        &::after {
          width: 45%;
          position: absolute;
          content: '';
          height: 1px;
          background-color: $gray-2;
          top: calc(50% - 0.5px);
          left: 1.5%;
        }

        &::after {
          left: 53.5%;
        }
      }

      .floating-input {
        width: 50%;
        background-color: $gray-1;
        padding: 0;
        height: 42px;
        margin-top: 0.25rem;

        input {
          padding: 10px;
          border: 1px solid $av-gray;
          border-radius: 4px;
        }

        .bar {
          display: none;
        }
      }
    }
  }
}
