@import 'css/_variables.scss';

.point-evidence {
  border-top: 1px solid $gray-3;
  background-color: $gray-1;

  .create-point-light-weight {
    .create-point-entry-form {
      border-radius: 8px;
    }
  }

  .selection-container {
    .tag-item {
      height: 40px;
      padding: 0 20px;
      line-height: 35px;
      margin-right: 15px;
    }
  }

  .evidence-actions {
    // .evidence-filter {
    //   .filters {
    //     .action-link {
    //       &.all {
    //       }
    //       &.for {
    //       }
    //       &.against {
    //       }
    //       &.active,
    //       &:hover {
    //       }
    //     }
    //     .filter {
    //     }
    //     .active {
    //     }
    //     .fa-filter {
    //     }
    //   }
    // }

    .card-or-list {
      min-width: 120px;
      display: flex;
      justify-content: space-between;
      // .action-link {
      //   &.active {
      //   }
      // }
    }
  }
  .add-evidence-row {
    display: flex;
    justify-content: center;
    // .add-evidence-link {
    // }
  }

  // .evidence-list {
  //   .evidence-scroll {
  //     padding-top: 40%;
  //     .show-evidence {
  //       font-size: 14px;
  //       font-weight: bold;
  //       margin-bottom: 10px;
  //     }
  //   }
  // }

  & > .sub-points {
    margin-left: 15px;
  }
}
