// .import-point {
//   .back-to-collection-row {
//     .icon {
//       display: inline-block;
//     }
//     .back-label {
//       position: absolute;
//       margin-top: 4px;
//       height: 30px;
//       display: inline-block;
//       margin-left: 10px;
//       cursor: pointer;
//       &:hover {
//         text-decoration: underline;
//       }
//     }
//   }
// }

// .points-container {
//   width: 100%;

//   .point-list {
//     width: 100%;
//     .point-card-main {
//       margin-bottom: 20px;
//     }
//   }
//   .selected-point {
//     width: 80%;
//     margin-left: 10%;
//   }
//   .point-stance {
//     margin-top: 15px;
//     width: 80%;
//     margin-left: 10%;
//   }
// }

.import-point-point-card-preview {
  width: 400px;
  max-width: 400px !important;
  .popover-content {
    padding: 0;
  }
}
