@import 'css/_variables.scss';

.review-point-form {
  background-color: $av-light-blue;
  border-radius: 8px;
  position: relative;

  .btn-close {
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 1;
  }

  .checkbox {
    margin-bottom: 16px;
    margin-top: 16px;

    .av-checkbox {
      font-weight: 600;
      padding-left: 0;
    }
  }

  .review-point-form-footer {
    background-color: $av-light-blue;
    border-top: 1px solid #c0cfda;
    bottom: 0;
    position: sticky;
    z-index: 1;
  }

  .create-point-entry-form {
    .hint {
      display: none;
    }

    .btn-submit {
      bottom: 2px;
    }

    &.lightweight-show .btn-submit {
      bottom: -32px;
    }
  }

  .btn-continue {
    background-color: $av-blue;
    color: $av-white;
    height: 40px;
  }
}
