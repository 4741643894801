.respond-invite-point{
	.modal-dialog{
		max-width:720px !important;
		width:auto;
	}
	font-family:openSans;
	.modal-title{
	 	font-size: 14px;
		font-weight: 800;
 		color: #1286c8;
	}
	.desc{
		font-size: 14px;
  		text-align: center;
  		color: #b7c7d0;
  		font-weight:600;
  		padding:30px;
	}
	.modal-footer{
		border : none !important;
		padding:0;
		padding-bottom:30px;
		.footrBtn{
			text-align:center;
			.btn-accept,
			.btn-reject{
				width: 130px !important;
		  		height: 40px;
		  		display : inline-block !important;
		  		margin:0 !important;
			}
			.btn-reject{
				border: solid 1.5px #f6511d;
				color : #f6511d;
				margin-right:10px !important;
			}
			.btn-accept{
				margin-left:10px !important;
			}
		}
	}
}