@import 'css/_variables.scss';

.question-body {
  padding: 2rem !important;

  &.body {
    background: $gray-1;
    border: 1px solid $gray-2;
    border-radius: 0.5rem;
    margin-top: 1rem;
    padding: 1rem 2rem;
  }

  .question-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-weight: bold;
      margin: 0;
    }

    .icon {
      color: $gray-3;
      cursor: pointer;
    }
  }

  .question-text-label {
    font-size: 10px;
    color: #CCCCCC;
    margin-bottom: 0;
  }

  .question-text-input {
    background-color: $av-light-gray;
    border-bottom-width: 1px !important;
    padding-left: 0px !important;
  }

  .input {
    width: 68%;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 2%;

    .floating-input {
      margin: 0;
    }
  }

  .dropdown {
    width: 30%;
    display: inline-block;
    vertical-align: bottom;

    .dropdown-selector {
      width: 100%;
      border: 0;
      border-bottom: 1px solid $gray-3;
      background-color: transparent;
      padding-bottom: 0.5rem;
      outline: none !important;
      color: $gray-4;
      font-weight: bold;
    }
  }

  .option-container {
    display: flex;
    align-items: flex-end;
    margin-top: 1rem;

    .icon {
      flex-shrink: 0;
      margin-right: 1.5rem;
      color: $gray-3;
      cursor: pointer;
    }

    .input {
      flex-grow: 1;

      label {
        margin: 0;
      }
    }

    .radio-correct {
      flex-shrink: 0;
      margin-left: 1rem;

      label {
        padding: 0;
        margin: 0 0 0 1rem !important;
      }
    }
  }

  .add-option {
    border-color: transparent;
    padding: 0;

    &:hover {
      background: transparent;
      color: $av-blue;
    }
  }

  .type-of-question-badge {
    margin-left: 1rem;
    background: linear-gradient(277.75deg, #006299 0%, #058EBA 99.25%);
    color: white;
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 10px;
  }

  .generated-badge {
    margin-left: 1rem;
    background-color: $av-dark-blue;
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 10px;
  }

  .teacher-modified-badge {
    margin-left: 1rem;
    background-color: $av-gray;
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 10px;
    color: white;
  }

  .stanford-badge {
    margin-left: 1rem;
    background-color: $av-dark-blue;
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 10px;
  }
}