@import 'css/_variables.scss';

.clubhouse-signup-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
        color: $av-blue;
      }
    }

    .topic-buttons {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.5rem;
      row-gap: 0rem;
    }
  }
}
