@import 'css/_variables.scss';

.collection-tree {
  border: 1px solid $av-grayish-cyan;
  border-radius: 2px;

  .search-container {
    border-bottom: 1px solid $av-grayish-cyan;
    input {
      border: none;
      outline: none;
    }
  }

  .collections {
    max-height: 250px;
    overflow-y: auto;

    .collections .collections .collection {
      &::before {
        content: '';
        position: absolute;
        top: 14px;
        width: 19px;
        height: 2px;
        background: #dbe1e4;
        left: -9px;
        z-index: 0;
      }
    }

    .collection {
      cursor: pointer;

      .expand {
        width: 40px;
        text-align: center;

        .action-link {
          width: 18px;
          height: 18px;
          padding: 0;

          .action-link-text {
            height: 100%;
            justify-content: center;
          }
        }

        &.expanded {
          &::before {
            content: '';
            position: absolute;
            top: 24px;
            bottom: 5px;
            width: 2px;
            background: $gray-2;
            left: 19px;
            z-index: 0;
            height: calc(100% - 29px);
          }
        }

        &.point {
          .action-link {
            &.add {
              background-color: $av-light-blue;
              border-radius: 100%;

              .icon {
                color: $av-blue;
              }
            }

            &.minus {
              background-color: $gray-3;
              border-radius: 100%;

              .icon {
                color: $av-white;
              }
            }
          }
        }
      }

      .status {
        width: 30px;
        text-align: center;

        & > div {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        &.point {
          & > div {
            background-color: $av-light-blue;
            border-radius: 100%;
            width: 18px;
            height: 18px;

            .icon {
              color: $av-blue;
            }
          }
        }
      }

      .icon {
        color: $av-gray;
      }

      &:hover {
        background: $av-light-blue;
      }

      &.selected {
        background: $av-blue !important;
        color: $av-white;

        .icon {
          color: $av-white;
        }
      }

      &.disabled {
        cursor: not-allowed;
        color: $gray-3;

        .icon {
          color: $gray-3;
        }
      }
    }
  }

  .switch-type {
    background-color: transparent !important;
    font-size: 16px;
    color: $gray-3;
    box-shadow: none !important;

    &.active {
      color: $av-blue !important;
    }
  }
}
