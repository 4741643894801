.publish-modal {
  font-family: 'Open Sans';
  .disableHover {
    pointer-events: none;
  }
  .modal-dialog {
    .modal-content {
      border: none;
      border-radius: 0;
      .modal-header {
        font-size: 16px;
        font-weight: 600;
        color: #979797;
        box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.5);
        border: solid 1px #f5f5f5;
        .fa-check-circle {
          display: inline-block;
          color: #1571bc;
          font-size: 20px;
        }
        .modal-title {
          display: inline-block;
          margin-left: 15px;
        }
        .fa-times {
          display: inline-block;
          float: right;
        }
      }
      .modal-body {
        text-align: center;
        font-size: 14px;
        color: #1286c8;
        .publishImg {
          margin-top: 80px;
        }
        .publishHeader {
          //font-family: OpenSans;
          display: flex;
          margin-left: 50px;
          font-size: 24px;
          font-weight: bold;
          line-height: 1.79;
          letter-spacing: 0px;
          //text-align: center;
          color: #333333;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        .description {
          font-size: 16px;
          font-weight: 600;
          line-height: 1.5;
          text-align: center;
          color: #b7c6cf;
          margin-bottom: 6px;
          margin-left: 50px;
          //margin-right: 20%;
        }
        .radioGroupOptions {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .radio-option {
            display: flex;
            justify-content: flex-start;
          }
        }
        .doneDiv {
          text-align: center;
          margin-top: 40px;
          .doneButton {
            //border-radius: 100px;
            //width: 130px;
            //height: 40px;
            display: inline-block;
            //text-align:center;
            //font-weight: 600;
            //font-size: 14px;
            //line-height:36px;
            margin-bottom: 10px;
          }
          .cancelButton {
            //border: solid 1.5px #b7c6cf;
            //color: #b7c6cf;
          }
          .tryagainButton {
            margin-left: 20px;
            //border: solid 1.5px #1286c8;
          }
        }
      }
    }
  }
}
