@import 'css/_variables.scss';

.floating-textarea {
  position: relative;
  margin: 0 0 20px;
  padding-top: 9px;

  textarea {
    border: none;
    border-bottom: 1px solid $av-grayish-cyan;
    color: #333;
    display: block;
    font-size: 14px;
    line-height: 1.42857;
    padding: 10px 0;
    width: 100%;
    resize: none;
    background: transparent;

    &::placeholder {
      opacity: 0;
    }

    &:focus {
      outline: none;

      &::placeholder {
        opacity: 1;
      }
    }
  }

  label {
    bottom: 10px;
    color: $av-gray;
    font-size: 14px;
    font-weight: normal;
    left: 0px;
    line-height: 1.42857;
    padding: 0;
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
  }

  textarea:focus ~ label,
  textarea:disabled ~ label,
  textarea:valid ~ label {
    font-size: 10px;
    font-weight: 600;
    left: 0;
    letter-spacing: 0.5px;
    line-height: 1;
    top: 0;
  }

  textarea:disabled ~ label {
    opacity: 0.25;
  }

  textarea:valid ~ label {
    color: #ccc;
  }

  textarea:focus ~ label,
  textarea:focus:valid ~ label {
    color: $av-blue;
  }

  .bar {
    bottom: 0;
    position: absolute;
    display: block;
    width: 100%;
  }

  .bar:after,
  .bar:before {
    content: '';
    height: 1px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: $av-blue;
    transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  span.error {
    float: right;
    position: absolute;
    right: 0;
    top: 23px;
    font-size: 10px;
    color: $av-red;
    font-family: 'Open Sans';
  }

  /* active state */
  textarea:focus ~ .bar:after,
  textarea:focus ~ .bar:before {
    width: 50%;
  }

  &.errorInput {
    textarea {
      border-bottom: 1px solid $av-red;
    }
  }

  @media (max-width: 767px) {
    margin: 0 0 10px;
    input {
      font-size: 12px;
    }
    label {
      font-size: 12px;
    }
    input[type='color'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='month'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    input[type='week'],
    select:focus,
    textarea {
      font-size: 12px;
    }
  }
}
