@import 'css/_variables.scss';

.profile-image {
  display: inline-block !important;
  margin: auto;
  border-radius: 50%;
  border: solid 1px $av-grayish-cyan;
  background: $av-white;

  &.clickable {
    &:hover,
    &.active {
      border: solid 1px $av-blue;
      color: $nickel;
    }
  }
  .image-overlay {
    width: 100%;
    background-color: $av-black;
    opacity: 0.4;
    top: 0;
    bottom: 0;
    border-radius: 50%;
  }
  .pencil-wrapper {
    position: absolute;
    top: 40%;
    left: 40%;
  }
  .profile-pencil-icon {
    color: $av-white;
  }
}
.user-profile-image {
  position: relative;
}
