@import "variables";
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
// $body-bg: $av-light-gray;
@import "bootstrap.scss";

@import "bs4-utils.css";
//@import 'utilities';
@import "typography";
@import "colors";
@import "./base.scss";
//@import './spacing.scss';
@import "./button.scss";
@import "./forms.scss";
@import "./icons.scss";
//To do: fix the tabs on the Fact page
//@import './overrides/tabs';
@import "~react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// @font-face {
//   font-family: 'AmericanTypewriter';
//   src: url('../assets/fonts/AmericanTypewriter.css'),
//     url('../assets/fonts/AmericanTypewriter.eot'),
//     url('../assets/fonts/AmericanTypewriter.ttf'),
//     url('../assets/fonts/AmericanTypewriter.woff');
// }
@import "../assets/fonts/AmericanTypewriter.css";

@import"~@draft-js-plugins/inline-toolbar/lib/plugin.css";

// @import "react-datepicker/dist/react-datepicker.css";
@import "./datepicker.scss";

main {
  box-sizing: border-box;
  overscroll-behavior: none;

  //overflow: hidden;
  @media (min-device-width: 768px) {
    //width: 95%;
    margin-left: 15px;
    margin-right: 15px;
  }
}

body {
  background-color: $av-light-gray;
}

@media (min-width: 768px) {
  .position-md-absolute {
    position: absolute;
  }
}

.dropdown,
.dropup {
  &.more-menu {
    .dropdown-toggle {
      background-color: transparent !important;
      border: none;
      padding: 0;
      color: $av-blue !important;
      box-shadow: none !important;

      .icon-more {
        margin: 0;
        width: 19px;
        height: 19px;
      }
    }

    .dropdown-menu {
      font-size: 14px;
      box-shadow: 0px 8px 22px rgba(51, 51, 51, 0.1);
      border-radius: 5px;
      background: $av-white;
      padding: 0;
      border-color: $av-grayish-cyan;

      .header {
        a {
          padding: 0;
        }
      }
    }
  }
}

.border-radius-25 {
  border-radius: 25px;
}

.popover {
  z-index: 111111;
}