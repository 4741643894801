.add-picture-component {
    background: white;
    .row{
        min-width: 400px;
        max-width: 500px;
    }

    .previewPhoto {
        width: 100px;
    }

    .photographerName {
        margin-top: 10px;
    }

    .originalURL {
        margin-top: 10px;
    }

    .acceptTerms {
        margin-top: 10px;
        margin-bottom: 15px;
    }

}