@import 'css/_variables.scss';

.av-checkbox {
  // color: #333;
  cursor: pointer;
  // font-weight: 400;
  margin-bottom: 0;

  input {
    display: none;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .fake-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px $av-blue;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    cursor: pointer;

    .icon-verified {
      fill: $av-blue;
      height: 9px;
      width: 10px;
      position: absolute;
      left: 2px;
      top: 2px;
      transform: rotate(10deg);
    }
  }

  .fake-checkbox-disabled {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: solid 1px $av-grayish-cyan;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    cursor: not-allowed;

    .icon-verified {
      fill: $av-grayish-cyan;
      height: 9px;
      width: 10px;
      position: absolute;
      left: 2px;
      top: 2px;
      transform: rotate(10deg);
    }
  }
}