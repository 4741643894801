@import 'css/_variables.scss';

.point-select-text {
  .marker {
    position: absolute;
    width: 1px;
    height: 24px;
    background-color: $av-blue;
    border-radius: 2px;
    top: 0;
    left: 0;
    cursor: pointer;

    .round {
      background-color: $av-blue;
      border-radius: 100%;
      height: 10px;
      left: -4px;
      position: absolute;
      top: -4px;
      width: 10px;
    }
  }

  .caption {
    &.selected {
      background-color: $av-light-blue;
    }
  }
}
