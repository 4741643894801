@import 'css/_variables.scss';

.loading-message {
  margin-left: 100px;
  margin-top: 50px;
}

.point-container {
  .review-widget-position {
    position: fixed;
    z-index: 500;

    @media screen and (max-width: 767px) {
      top: 0px;
      margin-top: 0px;
      width: 350px;
      padding-left: 0px;
      padding-right: 0px;
    }

    @media screen and (min-width: 767px) {
      top: 110px;
      margin-top: 50px;
      right: 26px;
    }
  }

  .glyphicon-publish {
    background: url(../../assets/images/publish.svg) no-repeat;
    background-size: contain;
    height: 12px;
    width: 12px;
  }

  .glyphicon-add-user {
    background: url(../../assets/images/invite.svg) no-repeat;
    background-size: contain;
    height: 12px;
    width: 14px;
  }

  .glyphicon-thumb-up,
  .glyphicon-thumb-down {
    background-size: contain;
    height: 21px;
    width: 21px;
  }

  .glyphicon-thumb-up {
    background: url(../../assets/images/support.svg) no-repeat;
  }

  .glyphicon-thumb-down {
    background: url(../../assets/images/oppose.svg) no-repeat;
    transform: translateY(3px);
  }

  .glyphicon-add {
    background: url(../../assets/images/add.svg) no-repeat;
    background-size: contain;
    height: 20px;
    width: 20px;
  }

  .glyphicon-shares {
    background: url(../../assets/images/share.svg) no-repeat;
    background-size: contain;
    height: 12px;
    width: 12px;
  }

  .glyphicon-globe-grey {
    background: url(../../assets/images/globe-grey.svg) no-repeat;
    background-size: contain;
    height: 16px;
    width: 16px;
    margin-bottom: -1px;
  }

  .glyphicon-lock-grey {
    background: url(../../assets/images/lock-grey.svg) no-repeat;
    background-size: contain;
    height: 16px;
    width: 16px;
    margin-bottom: -1px;
  }

  .glyphicon-left,
  .glyphicon-right {
    background-size: contain !important;
    height: 10px;
    width: 18px;
  }

  .glyphicon-left {
    background: url(../../assets/images/arrow-left.svg) no-repeat;
  }

  .glyphicon-right {
    background: url(../../assets/images/arrow-right.svg) no-repeat;
  }

  .error-message {
    //float: right;
    // margin-bottom: 20px;
    text-align: right;
  }
}

.left-arrow,
.right-arrow {
  padding: 18px 20px;
  border-radius: 30px 32px;
  position: absolute;
  top: 100px;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  //box-shadow: 0 0 2px 0 rgba(51, 51, 51, 0.1);
  cursor: pointer;

  &:hover {
    color: $av-blue;
  }
}

.right-arrow {
  right: 12px;
}

.left-arrow {
  left: 12px;
}

@media screen and (max-width: 767px) {
  .left-arrow {
    left: -10px !important;
    top: 52% !important;
    transform: scale(0.5) !important;
  }

  .right-arrow {
    right: -10px !important;
    top: 52% !important;
    transform: scale(0.5) !important;
  }
}

.sticky-point-header {
  .sticky-inner-wrapper {
    z-index: 1000;
    border-bottom: 1px solid #b7c6cf;
    // margin-bottom: 10px;
    background: #ffffff;
    left: 0px;
    width: 100% !important;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    border: bottom;
  }
}

// .point-page-component {
//   .four-pack-tabs {
//     background: $gray-1;
//   }
// }
