@import 'css/_variables.scss';

.comment-card {
  background-color: $av-white;
  border: 2px solid $av-light-blue;
  border-radius: 4px;

  .comment {
    background-color: rgba(232, 241, 248, 0.5);
    color: $gray-4;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 600;
  }

  .reply {
    font-size: 11px;
    padding: 5px 10px;
    border: 1px solid rgba(232, 241, 248, 0.5);
    border-radius: 4px;
    margin-left: 20px;
    margin-top: 10px;
  }
}
