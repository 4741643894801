@import 'css/_variables.scss';

.location-picker {
  .header {
    padding: 0;
    margin: 2rem 0 0;
    max-width: 574px;

    .row {
      margin: 0px !important;

      * {
        padding: 0;
        font-size: 1.25rem;
        color: $av-blue;
      }
    }

    .homeCity,
    .currentCity,
    .remove {
      text-align: center;
    }
  }

  .places {
    padding: 0;
    margin: 1rem 0 1rem;
    max-width: 574px;

    .row {
      margin: 0px !important;

      * {
        padding: 0;
        color: $av-blue;
      }

      .place {
        display: flex;
        align-items: flex-start;

        .icon {
          flex-shrink: 0;
          margin-top: 4px;
        }

        .user-selected-location {
          margin-left: 0.5rem;
          flex-grow: 1;
        }
      }

      .homeCity,
      .currentCity,
      .remove {
        text-align: center;
      }
    }
  }
}
