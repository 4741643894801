// .modal-body{
//     border: 1px solid blue;
//     .point-modal{
//     .container{
//             //border: 1px solid blue;
//             width: 100%;
//         }
//     }
// }
// .custom-modal{
//     width: 90% !important;
//     display: flex;
//     justify-content: center;
//     .modal-content{
//         width: 95%;
//         padding-left: 5px;
//         padding-right: 5px;
//         .modal-body{

//         }
//     }
// }
.picture-modal {
  .icon-close {
    color: red;
    cursor: pointer;
    float: right;
  }
  .container {
    //border: 1px solid blue;
    width: 100%;
  }
}
