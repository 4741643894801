@import 'css/_variables.scss';

iframe {
  .contact-component {
    display: none;
  }
}

.contact-component {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .heading {
    .title {
      color: $av-blue;
      font-size: 30px;
      border-bottom: 1px solid $av-blue;
      margin-bottom: 10px;
    }
    .description {
      color: $av-gray;
      font-size: 20px;
    }
  }
  .message {
    margin-top: 20px;
  }

  .contact-email {
    margin-top: 20px;
  }

  .submit-component {
    width: 80%;
  }

  .help-component {
    width: 40rem;

    .heading {
      .title {
        border-bottom: 0px;
      }
    }

    .rectangular-button {
      border-radius: 4px;
      background: linear-gradient(278deg, #1286c8 0%, #2bb5e0 99.25%);
      box-shadow: 0px 4px 8px 0px rgba(0, 77, 121, 0.21);
      color: $av-white;
      font-size: 18px;
      width: 145px;
      height: 40px;
      margin-top: 10px;

      &:hover {
        background: linear-gradient(278deg, #0273b2 0%, #15a3d0 99.25%);
      }

      &:active {
        background: linear-gradient(278deg, #005484 0%, #038bb6 99.25%);
      }

      &:first-child {
        margin-right: 16px !important;
      }
    }
  }

  .success-component {
    width: 80%;
    color: $av-gray;
    font-size: 20px;
    .submit-button {
      margin-top: 20px;
    }
  }
}

.contact-page {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .header {
    .title {
      color: $av-blue;
      font-size: 30px;
    }
  }

  .contacts-container {
    background: white;
    margin-top: 20px;
    padding: 2rem;

    .contacts-title,
    .follow-us {
      margin-bottom: 12px;
      font-weight: bold;
    }

    .contact {
      margin-bottom: 0.875rem;
      span {
        color: $av-blue;
      }
    }

    .follow-us {
      margin-top: 2rem;
    }

    .social-media {
      img {
        margin-right: 1rem;
      }
    }
  }

  .contact-page-form {
    background: white;
    margin-top: 20px;
    padding: 2rem;
  }
}
