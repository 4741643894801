@import 'css/_variables.scss';

.editable-tags-container {
  display: inline-flex;
  align-items: center;
  .icon-pencil {
    display: inline-block;
    // margin-bottom: 15px;
    //color: red;
  }
}

/* Display Tags - regular and piped */
.tags-container {
  display: inline-block;
  overflow: auto;

  .react-tags {
    display: inline;
  }

  &.piped {
    .tag-piped {
      display: inline-block;
      color: $av-gray;
    }
    .tag-piped {
      .tag-text:hover {
        text-decoration: underline;
      }
    }
    .tag-piped:first-child {
      border-left: none;
      padding-left: 0;
    }
    .tag-piped:after {
      content: '|';
      margin: 0 3px;
    }
    .tag-piped:last-child:after {
      content: '';
      margin: 0 3px;
    }
  }

  .tag {
    float: left;
    margin-right: 5px;
    // margin-bottom: 5px;
    border-radius: 25px;
    font-size: 12px;
    //color: #b7c6cf;
    font-weight: 600;
    //border: solid 1.5px #b7c6cf;
    border: solid 1px $av-gray;
    color: $av-gray;
    background: white;
    padding: 4.5px 15px;
    cursor: pointer;
    text-transform: capitalize;

    &:hover {
      //   //background-color: #1286c8;
      //   border: solid 1px #1286c8;
      //   color: #1286c8;
      //   //color: white;
      //   opacity: 0.5;
      border: solid 1px $av-gray;
      background: $av-gray;
      color: white;
      opacity: 0.5;
    }

    &.selected {
      background-color: $av-blue;
      border-color: $av-blue;
      color: $av-white;

      &:hover {
        color: $av-white;
      }
    }

    &.active {
      background-color: $av-blue;
      border-color: $av-blue;
      color: $av-white !important;
    }
  }

  .tags-help-text {
    display: inline-block;
    font-size: 10px;
    margin-left: 10px;
    padding-top: 10px;
  }

  &.tags-limit-reached {
    .react-tags__search-input {
      display: none;
    }
  }
}

/* Add tags - the new tags, search with suggestions */
.react-tags__search {
  display: inline-block;
  .react-tags__suggestions {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: $av-white;
    border: 1px solid $av-gray;
    border-top-color: $av-gray;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    ul {
      padding-left: 0px;
      li {
        box-sizing: border-box;
        background-color: $av-white;
        color: $av-gray;
        cursor: pointer;
        display: block;
        padding: 8px 0 8px 10px;
        border-bottom: 1px solid $av-white;
        &:hover {
          background-color: #ebf5ff;
          background-color: rgba(0, 126, 255, 0.08);
          color: #333;
        }
      }
    }
  }
  .react-tags__search-input {
    input {
      width: 100% !important;
      height: 30px;
      font-size: 12px;
      padding: 0 5px;
      border-radius: 25px;
      //border: solid 1.5px #b7c6cf;
      //outline: none;
      border: solid 1px $av-blue;
      color: $av-blue;
      background: $av-white;
    }
    &.piped {
      // margin-left: 10px;

      input {
        border-width: 0;
        background: transparent;
        border-bottom: 1px solid $av-gray;
        color: #333333;
        width: 70% !important;
        border-radius: 0;
        padding: 2px 0px;
        outline: none;
      }
    }
  }
}

/* Add Tags - the tags already added */
.react-tags {
  .react-tags__selected {
    margin-bottom: 5px;
    display: inline-block;
    .react-tags__selected-tag {
      padding: 2px 12px;
      height: 30px;
      margin-right: 10px;
      margin-top: 5px;
      //color:  #b7c6cf;
      border-radius: 25px;
      font-size: 12px;
      //border: solid 1.5px #b7c6cf;
      border: solid 2px $av-blue;
      color: $av-blue;
      background: $av-white;
      &.piped {
        color: $av-white;
        font-size: 14px;
        background: $av-blue;
        :first-child {
          border-left: none;
          padding-left: 0;
        }
        :after {
          content: '|';
          margin: 0 3px;
        }
        :last-child:after {
          content: '';
          margin: 0 3px;
        }
      }
    }
    .react-tags__selected-tag:hover {
      //color: #1286c8;
      ////border: solid 1px #1286c8;
      opacity: 0.8;
      ////&.piped {
      ////  color: $av-gray;
      ////  border: 0px;
      ////  text-decoration: underline;
      ////}
    }
  }
}

/* Add Tags - Close button for the added tags */

.react-tags__selected-tag-name {
  &:before {
    color: $av-gray;
    content: 'x';
    float: right;
    margin-left: 4px;
  }
  &.piped {
    &:before {
      margin-left: 0px;
      color: white;
    }
  }
}

@media screen and (max-width: 767px) {
  .tags-container {
    .tag {
      border-radius: 15px;
      font-size: 8px;
      border: solid 1px $av-gray;
      padding: 4.5px 15px;
    }
  }
}

/* Example Styles for React Tags*/
// div.ReactTags__tags {
//   position: relative;
//   padding-bottom: 10px;
// }

/* Styles for the input */
// div.ReactTags__tagInput {
//   width: 200px;
//   border-radius: 1px;
//   display: inline-block;
// }
// div.ReactTags__tagInput input,
// div.ReactTags__tagInput input:focus {
//   margin: 0;
//   outline: none;
//   font-size: 14px;
//   border: 1px solid #b9b9b9;
//   border-radius: 3px;
//   height: 45px;
//   width: 100%;
//   padding: 10px;
//   color: red;
// }

/* Styles for selected tags */
// div.ReactTags__selected span.ReactTags__tag {
//   border: 1px solid #ddd;
//   background: #eee;
//   font-size: 14px;
//   display: inline-block;
//   padding: 11px;
//   margin: 0 5px;
//   cursor: move;
//   border-radius: 3px;
// }

// div.ReactTags__selected a.ReactTags__remove {
//   color: #aaa;
//   margin-left: 10px;
//   cursor: pointer;
// }

// .react-tags__selected-tag-name:before{
//   color: #aaa;
//   content: "x";
//   float: right;
//   margin-left: 4px;
// }

/* Styles for suggestions */
// div.ReactTags__suggestions {
//   position: absolute;
//   z-index: 1;
// }
// div.ReactTags__suggestions ul {
//   list-style-type: none;
//   box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
//   background: white;
//   width: 200px;
//   padding: 0;
//   color: red;
// }
// div.ReactTags__suggestions li {
//   border-bottom: 1px solid #ddd;
//   padding: 5px 10px;
//   margin: 0;
//   color: blue;
// }
// div.ReactTags__suggestions li mark {
//   text-decoration: underline;
//   background: none;
//   font-weight: 600;
//   color: green;
// }
// div.ReactTags__suggestions ul li.active {
//   background: #b7cfe0;
//   cursor: pointer;
//   color: purple;
// }
