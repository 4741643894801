@import 'css/_variables.scss';

.media-collection-modal {
  .media-type {
    margin-bottom: 20px !important;

    select {
      width: 100%;
      border: none;
      border-bottom: 1px solid $av-blue;
      border-radius: 0;
      background: none;
      outline: none;
      appearance: none;
      padding-bottom: 10px;
      margin-bottom: 10px;
    }

    .error {
      font-size: 10px;
      color: $av-red;
    }
  }
}
