@import 'css/_variables.scss';

.a-drop-down {
  height: 50px;
  position: relative;
  margin: 12px 0 20px;

  label {
    color: #ccc;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.42857;
    padding: 0;
    pointer-events: none;
    transition: 0.2s ease all;
    margin-bottom: 0;
  }

  .required-field {
    // color: $av-red;
    margin-left: 0.5rem;
  }

  @media (max-width: 767px) {
    margin: 0 0 10px;

    label {
      font-size: 12px;
    }
  }
}
