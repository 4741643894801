@import 'css/_variables.scss';

.manage-quiz-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .prefill-button {
      border-color: transparent;

      &:hover {
        background-color: transparent;
        color: $av-blue;
      }
    }

    .generate-question {
      position: relative;

      .hide-label {
        visibility: hidden;
      }

      .generate-question-loader {
        position: absolute;
        left: 1.25rem;
        top: 4px;
      }
    }

    .add-question-button:disabled {
      background-color: $gray-1 !important;
      color: $av-gray !important;
    }

    .reading-questions {
      // background-color: $gray-1;
      // padding: 1rem;
      // margin-bottom: 2rem;

      .next-button-container {
        text-align: right;
      }
    }

    .readings-container {
      td {
        padding: 0.5rem 0;
      }

      .reading-status-column {
        vertical-align: top;
      }

      .readings {
        display: flex;
        align-items: flex-start;
        // width: 80%;

        .source-logo {
          flex-shrink: 0;
          margin-top: 0.25rem;
        }

        .reading-title {
          flex-grow: 1;

          .reading-url {
            color: $gray-4;
          }

          .reading-error-message {
            font-size: 1.2rem;
          }
        }
      }

      .reading-status {
        flex-shrink: 0;
        display: inline;

        .check,
        .add-icon {
          background-color: $av-light-blue !important;
          border-radius: 10px;
          height: 20px;
          // margin-right: 12px;
          width: 20px;
          flex-shrink: 0;
          margin-top: 4px;

          .icon {
            margin-top: 0;
          }

          &.success {
            background-color: $av-green !important;
          }

          &.error {
            background-color: $av-red !important;
          }
        }

        .add-icon {
          background-color: $av-blue !important;
        }
      }
    }
  }
}