@import 'css/_variables.scss';

.landing-copy {
  background-color: $av-white;
  border-radius: 6px;
  margin-top: 48px;

  .content-info {
    padding-top: 56px;
    padding-bottom: 40px;

    .title {
      font-size: 36px;
      font-weight: 700;
    }

    .sub-title {
      font-size: 24px;
    }

    .how-it-works {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 24px;
      margin-top: 56px;
    }

    li {
      margin-bottom: 11px;
    }
  }

  .footer {
    position: relative;
    background-color: $av-light-blue;
    color: $av-blue;
    padding: 32px 67px;
    height: auto;

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .rectangular-button {
      margin-top: 32px;
      height: 52px;
      width: 322px;
      border-radius: 4px;
      font-size: 14px;
      max-width: 100%;
    }
  }
}
