@import "css/_variables.scss";

#highlight-container{

}

.highlight-header{
  color: $av-blue;
  //background: white;
  //border-bottom: 1px solid $av-gray;
}

.highlight-content {
  padding: 1rem;
  color: #333333;
  z-index: 99999;
  .btn {
    margin-top: 1rem;
    border-width: 1px !important;
  }
}
