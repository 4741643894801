@import 'css/_variables.scss';

.interaction-review {
  // border: 1px solid $gray-3;
  // border-bottom: 1px solid $av-light-blue;
  // margin-top: 10px;
  //   margin-left: 30px;
  // padding: 5px 20px;
  // border-radius: 4px;
  // font-size: 12px;
  // margin-left: -1rem;
  // margin-right: -1rem;
  // background-color: $av-light-blue;
  width: 400px;

  .btn.approved,
  .btn.rejected {
    border-radius: 100%;
    height: 20px;
    padding: 0;
    width: 20px;
    border: 1px solid $gray-2;
    background-color: $av-white;

    .icon {
      display: none;
    }
  }

  .btn.approved.green,
  .btn.approved:hover {
    border-color: $av-green;
    color: $av-green;

    .icon {
      display: block;
    }
  }
  .btn.rejected.red,
  .btn.rejected:hover {
    border-color: $av-red;
    color: $av-red;

    .icon {
      display: block;
    }
  }

  .comment {
    font-size: 12px;
  }

  .form-control.comment {
    border-color: $gray-1;
  }
}

.interaction-reviews {
  position: relative;

  .review {
    position: relative;
  }
}
