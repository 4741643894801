@import 'css/_variables.scss';

.subscribe-newsletter {
  padding-bottom: 16px;
  padding-top: 16px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100000;
  background-color: #faebd7;

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }

  &.static {
    padding-bottom: 32px;
    padding-top: 32px;
    position: static;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    margin-top: 11px;
  }

  input {
    font-size: 14px;
    height: 48px;
    padding-left: 17px;
    border: 2px solid white;
    width: 32rem;
    margin-left: 2rem;
    margin-bottom: 0 !important;
    border-radius: 0 !important;
    outline: none !important;

    &.error {
      border-color: $av-red;
      border-radius: 4px;
      outline: none;
    }
  }

  .rectangular-button {
    height: 48px;
    border-radius: 0 !important;
    background-color: $av-blue;
    color: white;
    width: 8rem;
    border: 0 !important;

    &:hover {
      background-color: #075682;
    }
  }

  .form-container {
    position: relative;
  }

  .error-message {
    margin-left: 2rem;
  }

  .subscribe-button {
    .content {
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    .main-container {
      flex-direction: column;
    }

    input {
      width: calc(80vw - 8rem);
      margin-left: 0;
      margin: auto;
    }

    .rectangular-button {
      width: 8rem;
    }

    .title {
      font-size: 16px;
      line-height: 20px;
    }

    .title-container {
      margin-bottom: 1rem;
    }

    .error-message {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
}