@import "css/_variables.scss";

.review-summary {
  .summary-head {
    color: #333333;
    font-size: 16px;
    line-height: 30px;
    border-bottom: 1px solid #B7C6CF;
      @media screen and (max-width: 767px){
        margin-top: 0px;
        line-height: 30px;
        .head-label{
          font-weight: 450;
          font-size: 14px;
        }
      }
  }
  .review-count {

    margin-top: 10px;
    .stats{
      margin-bottom: 10px;
      .status-row{
        .label{
          color: black;
          font-size: 14px;
          font-weight: 600;
        }
        .status{

        }
        .level-section{
          margin-left: 30px;
        }
      }
      .breakdown-row{

        margin-left: 20px;
        font-size: 12px;
        .verifications{
          margin-left: 50px;
          .icon{
            color: $av-green;
          }
        }
        .rejections{
          margin-left: 50px;
          .icon{
            color: $av-red;
          }
        }
      }
      .criteria-row{
        font-size: 12px; 
        margin-top: 15px;
        
        .neutral, .balanced{
          display: inline-block;
          width: 150px;
        }
        .sources, .factual{
          display: inline-block;
          width: 150px;
        }
        .percent-graph{
          display: block;
          height: 50px;
        }
        .percent-label{
          display: block;
          color: black;
        }
      }
    }
    .head-label {
      font-size: 14px;
      font-weight: 600;
      @media screen and (max-width: 767px){
        font-size: 12px;
        font-weight: 300;
      }
      &.tab-line {
        border-bottom: 3px solid #eaeaea;
        padding-bottom: 8px;
      }
      &.active {
        border-bottom: 3px solid #333333;
      }
    }

    .outOfLabel{
      font-weight: normal;
    }
    .agreePercentLabel{
      margin-left: 5px;
    }
    .agreeLineGraph{
      width: 80%;
    }

    .process {
      font-size: 12px;
      margin-top: 10px;
    }
  }
  .select-box {
    border-top: 2px solid #eaeaea;
    border-bottom: 2px solid #eaeaea ;
    padding: 10px 0;
    .list-unstyled {
      margin-bottom: 0;
    }
    .search {
      border: none;
      width: 95%;
      &:focus{
        outline-color: white;
      }
    }
  }

  .show-reviews-link{
    color: $av-blue;
    text-decoration: underline;
    cursor: pointer;
  }

  .review-list{
    .reviewers-label{
      font-weight: 600;
    }
    .hide-link{
      color: $av-blue;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .user-list {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    .review-list-item{
      margin-left: 20px;
      &.header{
        text-decoration: underline;
        //font-weight: bold;
      }
      .username{
        display: inline-block;
        width: 100px;
        &.header{
          text-decoration: underline;
          //font-weight: bold;
        }
      }
      .review{
        display: inline-block;
        width: 100px;
        text-align: center;
        &.header{
          text-decoration: underline;
          //font-weight: bold;
        }
      }
      .challenge{
        display: inline-block;

      }
      .challenge-row{
        color: red;
        margin-top: 3px;
        margin-bottom: 3px;
      }
      .review-level{
        display: inline-block;
        width: 100px;
        text-align: center;
        margin-left: 3px;
        &.header{
          text-decoration: underline;
          //font-weight: bold;
        }
      }
    }
    .review-list-item:hover{
      background-color: #EDEDED;
      cursor: pointer;
    }
    .review-info {
      .vertical-border {
        margin: 0 4px;
        border-right: solid 1px #b7c6cf;
      }
      .next-step {
        position: absolute;
        right: 0;
      }
    }
  }
}