@import 'css/_variables.scss';

.selection-container {
  ul {
    padding: 0;
    margin-top: 13px;

    li {
      display: inline-block;
      text-decoration: none;
      margin-bottom: 12px;
    }
  }

  label {
    .roundTagToolTip {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px 12px 6px 0px;
    }
  }

  .tag-item {
    text-align: center;
    margin-right: 5px;
    height: 30px;
    padding: 0 10px;
    line-height: 26px;
    border-radius: 25px;
    border: solid 1px $av-gray;
    color: $av-gray;
    background: $av-white;
    cursor: pointer;
    transition: all 0.2s ease;

    input[type='radio'] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }

    &.selected {
      background-color: $av-blue;
      border-color: $av-blue;
      color: $av-white;

      &:hover {
        color: $av-white;
      }
    }

    &:hover {
      background-color: $av-blue;
      border: solid 1.5px $av-blue;
      color: $av-white;
    }

    &:focus {
      border: solid 2px $av-blue;
    }

    &.disabled {
      background-color: $av-gray;
      border-color: $av-gray;
      color: $av-white;
    }

    .tagToolTip {
      &::after {
        //border: 1px solid red;
        //height: 200px;
        //word-wrap: word-break;
      }
    }
  }

  &.read-only {
    .tag-item {
      color: $av-blue;
      border-color: $av-blue;

      &:hover,
      &:focus {
        background: $av-white;
      }
    }
  }
}
