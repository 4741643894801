@import 'css/_variables.scss';

.search-items {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  //border-radius: 6px;
  // padding: 4px;
  background: $av-white;
  .icon {
    &.icon-search {
      margin-left: 5px;
      color: $av-grayish-cyan;
    }
    &.icon-close {
      float: right;
      margin-right: 2px;
      color: $av-red;
      width: 8px !important;
      cursor: pointer;
    }
  }
  input {
    border: none;
    margin-left: 10px;
    color: black;
    font-weight: normal;
    background: transparent;
    font-size: 14px;
    &:hover,
    &:focus,
    &.active,
    &:active,
    &:active:focus,
    &.active:focus {
      box-shadow: none;
      //color: #1286c8;
      outline: none;
      text-decoration: none;
    }

    &::placeholder {
      color: $av-grayish-cyan;
    }
  }
  // @media (max-width: 767px) {
  //   display: none;
  // }
}
