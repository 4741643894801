@import 'css/_variables.scss';

.alert-container {
  bottom: 0;
  z-index: 2147483647;

  &.on-top {
    top: 70px !important;
    bottom: unset !important;
  }

  &.lift-up-hint {
    bottom: 80px;

    @media (max-width: 767px) {
      bottom: 130px;

      &.subscribed-newsletter {
        bottom: 72px;
      }
    }
  }

  .alert {
    margin-bottom: 0px;
    color: $av-black;
    border-radius: 0;
  }

  .alert-info {
    background-color: #fff0cc;
    border-color: #fff0cc;
  }

  .hint-icon-container>svg {
    color: $av-camel;
  }

  // .slideup,
  // .slidedown {
  //   // max-height: 150px;
  //   // overflow-y: hidden;
  //   // transition: max-height 0.5s ease-in;
  // }
  // .slidedown {
  //   // max-height: 0px;
  //   // padding: 0px;
  // }
  .checkbox {
    margin: 0px;
  }

  .info-icon {
    color: $av-blue;
  }

  .hint-icon-container {
    flex-shrink: 0;
    margin-top: 0.4rem;
  }

  .close {
    color: black !important;
    font-size: 24px !important;
    opacity: 0.7 !important;
  }
}