@import 'css/_variables.scss';

.btn-container {
  display: inline-block;

  .btn {
    cursor: pointer;
    border-radius: 25px;
    border: solid 1px $av-blue;
    //border: solid 1.5px #b7c6cf;
    color: $av-blue;
    //color: #b7c6cf;
    background: transparent;
    font-size: 14px;
    font-weight: 600;
    display: inline;
    margin: 0 auto;
    padding: 7px 30px;
    transition: all 0.2s ease;
    &.secondary-btn {
      color: $gray-3;
      border: solid 1px $gray-3;
    }
    &.danger-btn {
      color: $av-red;
      border: solid 1px $av-red;
      &:hover {
        border: solid 1px $av-red;
        background: $av-red;
        color: $av-white;
        //color: #1286c8;
      }
      &:active {
        border: solid 1.5px $av-red;
        background: $av-red;
        color: $av-white;
        opacity: 1;
      }
      &:focus {
        border: solid 1.5px $av-red;
        color: $av-red;
        background: $av-white;
      }
    }
    .icon {
      margin-right: 6px;
    }
    &:hover {
      border: solid 1px $av-blue;
      background: $av-blue;
      color: $av-white;
      //color: #1286c8;
    }
    &:active {
      border: solid 1.5px $av-blue;
      background: $av-blue;
      color: $av-white;
      opacity: 1;
    }
    &:focus {
      border: solid 1.5px $av-blue;
      color: $av-blue;
      background: $av-white;
    }
    &.btn-sm {
      padding: 4px 12px;
    }
    &.button-small {
      padding: 4px 12px;
      font-size: 12px;
    }
    &.green {
      color: $av-green;
      border-color: $av-green;
      &:hover {
        background: $av-green;
        color: $av-white;
      }
    }
    &.red {
      color: $av-red;
      border-color: $av-red;
      &:hover {
        background: $av-red;
        color: $av-white;
      }
    }

    .has-hover-label {
      .hover-label {
        display: none;
      }
    }
  }

  .btn[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    border-radius: 25px;
    border: solid 1px $av-grayish-cyan;
    color: $av-grayish-cyan;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    &:hover,
    &:active {
      border: solid 1px $av-grayish-cyan;
      background: $av-white;
      color: $av-grayish-cyan;
    }
  }

  &:hover {
    .has-hover-label {
      .label {
        display: none;
      }

      .hover-label {
        display: inline;
      }
    }
  }
}
