@import 'css/_variables.scss';

.sub-point {
  .expand-sub-points-with-count {
    line-height: 1rem;

    .icon {
      background-color: $av-green;
      border-radius: 100%;
      width: 20px;
      margin-left: -1px;
      margin-right: 15px !important;
    }
  }
}
