@import 'css/_variables.scss';

.course-tab {
  padding-bottom: 5rem;

  .article-header,
  .goal-header,
  .user-header {
    border-bottom: 1px solid $gray-3;
    font-weight: 600;
  }

  .breadcrumb-link {
    cursor: pointer;
  }

  // .check {
  //   margin-left: auto;
  //   margin-right: auto;

  //   &.green {
  //     background-color: $av-green;
  //   }
  // }

  .article-item {
    .title {
      cursor: pointer;
      overflow-wrap: anywhere;

      &.read {
        opacity: 0.4;
      }

      &:hover {
        color: $av-blue;
      }
    }

    .reading-goals {
      cursor: pointer;
    }
  }

  .user-item {
    cursor: pointer;

    &:hover {
      background-color: $gray-1;
    }
  }

  .refresh-button {
    font-weight: bold;
    color: $av-blue;
    cursor: pointer;

    .refresh-icon {
      color: $av-blue;
    }
  }

  .dropdown,
  .dropup {
    &.more-menu {
      .dropdown-toggle {
        color: $gray-3 !important;
      }
    }
  }

  .dropdown-menu {
    li {
      a {
        display: flex;
        align-items: center;
        padding: 7px 20px;
        color: currentColor;

        &:hover {
          color: currentColor;
        }

        & > .icon {
          color: $av-grayish-cyan;
          width: 16px;
        }
      }

      &.av-red a {
        .icon {
          color: $av-red;
        }
      }
    }
  }

  .article-container {
    position: relative;

    &.title {
      overflow-wrap: anywhere;
    }

    .summary-links {
      border-top: 1px solid $gray-2;

      div {
        color: #1286c8;
      }

      div:hover {
        color: #034e79;
      }
    }
  }

  .tooltip-context-readings {
    &::after {
      min-width: 200px;
      line-height: 20px;
    }
  }

  .visibility-badge {
    background-color: $av-gray;
    color: white;
    margin-left: 0.5rem;
    padding: 1px 3px;
    border-radius: 4px;
    font-size: 10px;
  }
}

.check {
  background-color: $av-light-blue;
  border-radius: 10px;
  height: 20px;
  margin-right: 12px;
  width: 20px;
  flex-shrink: 0;
  margin-top: 4px;

  &.goal {
    margin: auto;
  }

  &.checked {
    background-color: $av-green !important;
  }

  &.error {
    background-color: $av-red !important;
  }

  &.warning {
    background-color: $av-gold !important;
  }
}

@media (max-width: 768px) {
  .course-tab {
    .title {
      .reading-source-logo {
        flex-shrink: 0;
      }

      a,
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
