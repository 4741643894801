@import 'variables';
.btn {
  .icon {
    margin-right: 4px;
  }
}

.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: none;
}

.btn-outline {
	background-color: #fff;
	border-radius: 25px;
	border-style: solid;
  border-width: 1.5px;
  font-size: 12px;
  font-weight: 600;
  height: 30px;
  line-height: 28px;
  padding: 0 10px;
  text-align: center;
  &.btn-default {
    background-color: white;
    border-color: #b7c7d0;
    color: #b7c7d0;
    &:hover,
    &:focus {
      border-color: #b7c7d0;
      color: #b7c7d0;
    }
    &.selected {
      background-color: #1286c8;
      border-color: #1286c8;
      color: #fff;
    }
  }
  &.btn-primary {
    border-color: #1286c8;
    color: #1286c8;
    &:hover,
    &:focus {
      background-color: #1286c8;
      color: #fff;
    }
  }
  &.btn-lg {
    font-size: 14px;
    height: 40px;
    line-height: 38px;
  }
  .icon {
    position: relative;
    top: -2px;
    vertical-align: middle;
  }
}
