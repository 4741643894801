@import '../../../css/variables';
.create-or-import-tabs,
.login-tabs {
  > .nav-tabs {
    display: flex;
    align-items: flex-end;
    border-bottom: none;
    padding: 0 15px;
    margin-bottom: 15px;

    > li {
      opacity: 0.5;
      flex: 1;

      > a {
        border-width: 0;
        border-bottom: 1px solid #b7c7d0;
        color: #333;
        display: block;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.21;
        text-align: center;
        // margin: 0;
        // padding: 0 10px 13px;
        &:hover {
          background-color: #fff;
        }
        .icon {
          color: $av-grayish-cyan;
          margin-right: 3px;
          vertical-align: middle;
        }
        .icon-pencil {
          width: 12.5px;
        }
      }

      &.active {
        opacity: 1;
        > a,
        > a:hover,
        > a:focus {
          border-width: 0;
          border-bottom: 2px solid $av-blue;
          font-weight: 600;
          color: $av-blue;

          .icon {
            color: $av-blue;
          }
        }
      }
    }
  }

  .privacy-save-container {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
}

.create-point-tab {
  // margin: 0 auto;
  //max-width: 400px;
  // max-width: 85%;
  //border: 1px solid red;
  > .nav-tabs {
    border-bottom: none;
    //margin-bottom: 20px;
    > li {
      width: 190px;
      &:first-child {
        margin-right: 20px;
      }
      > a {
        border: none;
        margin: 0;
        padding: 0;
        &:hover {
          background-color: #fff;
        }
        h3 {
          border-bottom: 2px solid transparent;
          display: inline-block;
          font-size: 18px;
          font-weight: bold;
          line-height: 1.22;
          margin: 0 0 10px;
          opacity: 0.5;
          padding: 0 10px 8px 22px;
          position: relative;
        }
        .point-tab-title {
          color: $av-blue;
          &:before {
            content: ' ';
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background-color: $av-blue;
            position: absolute;
            left: 0;
            top: 5px;
          }
        }
        .fact-tab-title {
          color: $av-camel;
          &:before {
            content: ' ';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6px 12px 6px;
            border-color: transparent transparent #c28f4c transparent;
            position: absolute;
            left: 0;
            top: 5px;
          }
        }
        p {
          font-size: 12px;
          line-height: 1.5;
          color: #b7c7d0;
        }
      }
      &.active {
        > a,
        > a:hover,
        > a:focus {
          border: none;
        }
        h3 {
          opacity: 1;
        }
        .point-tab-title {
          border-bottom-color: $av-blue;
        }
        .fact-tab-title {
          border-bottom-color: $av-camel;
        }
      }
    }
  }
  &.isExtension {
    //max-width: 70%;
    > .nav-tabs {
      border-bottom: none;
      margin-bottom: 20px;
      > li {
        width: 190px;
      }
    }
  }
}

.create-point-light-weight {
  .point-image {
    max-height: 300px;
    overflow: hidden;
  }

  .privacy-save-container,
  .collection-container,
  .invite-authors-container {
    display: none !important;
  }

  .create-point-entry-form {
    max-height: 56px;
    // transition: max-height 2s ease-in-out;
    overflow: hidden;

    &.show-stance {
      max-height: 100px;
    }

    &.lightweight-show {
      max-height: 2000px !important;
      overflow: visible;

      .btn-submit {
        bottom: -6px;
      }
    }

    .btn-submit {
      bottom: 33px;
      position: absolute;
      right: 0;
      // transition: bottom 0s 1s ease-in-out;
    }

    .hint {
      margin-right: 120px;
    }
  }

  .import-point-link {
    color: $av-blue;
    left: 110px;
    position: absolute;
    text-decoration: underline !important;
    top: 59px;
    z-index: 1;
  }
}

.collection-container,
.invite-authors-container {
  border: 1px solid $gray-2;
  border-radius: 4px;
  min-height: 40px;
}

.invite-authors-container {
  .invite-authors {
    border: none;
  }
}

@media (max-width: 767px) {
  .create-point-tab {
    > .nav-tabs {
      > li {
        width: 120px;
      }
    }
  }
  .point-start-component {
    .btn-container {
      .back {
        margin-bottom: 50px;
      }
    }
  }
}
