@import '../../../../css/_variables.scss';

.topic-selection {
  .topic-button {
    display: inline;
    margin: 0 0.75rem;
  }

  .selected-topic {
    background-color: $av-blue !important;
    color: white !important;
    font-weight: bold;
  }

  .predicted-topic {
    background-color: $av-camel !important;
    color: white !important;
    font-weight: bold;
  }
}
