@import 'css/_variables.scss';

.invite-reponse {
  .accept {
    color: $av-green;
    border: 1px solid rgba($color: $av-green, $alpha: 0.2);
    border-radius: 15px;

    &:hover {
      color: $av-white;
      background-color: $av-green !important;
    }
  }

  .decline {
    color: $av-red;
    border: 1px solid rgba($color: $av-red, $alpha: 0.2);
    border-radius: 15px;

    .icon-close {
      color: $av-red;
    }

    &:hover {
      color: $av-white;
      background-color: $av-red !important;

      .icon-close {
        color: $av-white;
      }
    }
  }
}
