@import 'css/_variables.scss';

.qtp-card {
  background-color: $av-white;
  border: 2px solid $av-light-blue;
  border-radius: 4px;

  .captions {
    background-color: rgba(232, 241, 248, 0.5);
    color: $gray-4;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
  }

  .dropdown,
  .dropup {
    &.more-menu {
      .dropdown-toggle {
        color: $gray-3 !important;
      }

      .dropdown-menu {
        box-shadow: 0px 8px 22px rgba(51, 51, 51, 0.1);
        border: none;
        border-radius: 5px;

        a {
          display: flex;
          align-items: center;
          color: $av-black;

          .icon {
            color: $gray-3;
          }
        }
      }
    }
  }
}
