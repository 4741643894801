@import "css/_variables.scss";

iframe{
    //border: 1px solid red;
    display: flex;
    align-items: center;
    //margin: 0px auto 0px auto;
    padding: 0px 20px;
    max-width: 100%;
    height: 500px;
}

.collection-list-embed{
    border: 1px solid $av-gray;
    // height: 500px;
    .header-row{
        .actions-row{

        }
        .title-row{
            border-bottom: 1px solid $av-gray;
            padding: 3px 20px 3px 20px;
            .title{
                font-weight: 800;
            }
            .total-points{

            }
        }
    }
    .list{
        padding: 3px 20px 3px 20px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        padding-top: 50px;

        .point{
            padding: 3px 0px 3px 0px;
            border-bottom: 1px solid $av-gray;
            
            &:hover{
                background: $av-gray;
            }
        }
        .point:last-child {
            border-bottom: 0px;
          }
    }
    .footer{
        height: 30px;
        border-top: 1px solid $av-gray;
        background: white;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;
        .text{
            color: $av-gray;
            margin-right: 10px;
        }
    }
}