@import 'variables';

a:focus {
  outline: none;
}

.error-message {
  color: $error;
}

/*
  Prevent scroll bars from being hidden for MacOS trackpad users in WebKit/Blink
  see: https://stackoverflow.com/a/7855592
*/
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.pac-container {
  z-index: 2000 !important;
}
