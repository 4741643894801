@import 'css/_variables.scss';

@media (max-width: 767px) {
  .notifications-list {
    left: -300px !important;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   position: fixed;
    //   background-color: #fff;
    //   padding: 20px 0 20px 20px;
    //   z-index: 10000;
    //   transition: left 0.3s linear;

    //   &.closed {
    //     left: 100%;
    //   }
    //   &.open {
    //     left: 0;
    //   }

    //   .go-back {
    //     margin-bottom: 20px;
    //     padding: 0;
    //     .icon {
    //       width: 6px;
    //     }
    //   }
  }
}

// @media (min-width: 768px) {
.navbar-notifications-container {
  width: 30px;
  //height: 50px;
  position: relative;
  //display: inline-block;
}
#notifications-bell,
.notifications-list {
  position: absolute;
  top: -8px;
  left: 8px;
  width: 100%;
  height: 100%;
}
.notification-icon {
  .icon {
    height: 17px;
    width: 17px;
  }
  &.has-notifications {
    color: red;
  }
}
.notification-oval {
  //position: absolute;
  border-radius: 20px;
  color: #ffffff;
  background-color: #b7c6cf;
  &.has-notifications {
    color: #ffffff;
    background-color: #f6511d;
  }
  border: solid 1px #ffffff;
  padding: 3px 7px;
  margin-left: -8px;
  //top: 20px;
  //left: 34px;
  line-height: 8px;
  //height: 15px;
  //width:15px;
  font-size: 8px;
  font-weight: bold;
  text-align: right;
  color: #fff;
}
.notification-icon:hover {
  font-weight: bold;
}
.notifications-list {
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(51, 51, 51, 0.1);
  padding: 0;
  position: absolute;
  top: 35px;
  left: -270px;
  width: 350px;
  height: auto;
  min-height: 100px;
  max-height: 400px;
  // overflow: auto;
  z-index: 1001;

  &.closed {
    display: none;
  }

  .notification-count {
    border-bottom: 1px solid #b7c7d0;
    line-height: 1.21;
    text-align: left;
    color: #b7c7d0;
  }

  .mark-all-as-read {
    border-top: 1px solid #b7c7d0;
  }

  .notification {
    font-size: 14px;
    line-height: 1.5;
    padding: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid #b7c7d0;
    }
  }

  .go-to-my-invite {
    text-decoration: none;
    border: 1px solid $av-blue;
    border-radius: 25px;
    padding: 3px 16px;
    font-weight: bold;
  }

  .notifications {
    max-height: 200px;
    overflow-y: auto;
  }
}
// }
