@import 'css/_variables.scss';

.point-image {
  .carousel-placeholder {
    background-color: $av-light-gray;
    border-radius: 8px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .controls {
    height: 40px;
    top: 0;
    left: 0;

    // .navigation,
    .capture-area,
    .upload-picture,
    .close-image {
      height: 40px;
      width: 40px;
      border-radius: 24px;
      background-color: rgba($color: #000000, $alpha: 0.8);
      color: #ffffff;
    }

    .navigation {
      .icon {
        width: 40px;
        height: 40px;
        background-color: rgba($color: #000000, $alpha: 0.8);
        color: #ffffff;

        &.icon-chevron-left {
          border-top-left-radius: 24px;
          border-bottom-left-radius: 24px;
        }

        &.icon-chevron-right {
          border-top-right-radius: 24px;
          border-bottom-right-radius: 24px;
        }

        &.disabled {
          opacity: 0.2;
        }
      }
    }
  }

  .carousel-inner > .item > img,
  .image {
    // height: 300px;
    // max-width: 100%;
    max-width: 400px; // temp fix for split screen Sept 10 2020
  }
}
