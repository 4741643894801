@import 'css/_variables.scss';

.sync-courses-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .checkbox {
      display: inline-block;

      .av-checkbox {
        padding-left: 0.5rem;
      }
    }

    li {
      margin: 1rem 0;
    }

    .topic-row {
      margin-left: 2rem;
    }

    .topic-update {
      margin-left: 1rem;
    }

    .course-status {
      font-size: 1.1rem;
    }

    .yellow {
      color: $av-camel;
    }

    .green {
      color: $av-green;
    }

    .red {
      color: $av-red;
    }

    .no-course-message {
      margin: 1rem;
      color: $gray-4;
    }

    .steps-container {
      width: 100%;
      padding: 0 2rem 1rem;
      display: flex;
      margin-bottom: 3rem;

      .step-container {
        width: 50%;
        position: relative;

        .step {
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          position: relative;
          flex: 1;
          flex-shrink: 0;

          &.active {
            background-color: $av-dark-blue;
            border: 3px solid $av-blue;
            color: $av-blue;
            font-weight: 600;
          }

          &.inactive {
            background-color: $gray-2;
            border: 3px solid $gray-4;
          }
        }

        .step-name {
          position: absolute;
          bottom: -1.75rem;
          left: calc(50% - 10rem);
          width: 20rem;
          text-align: center;
          font-size: 1rem;
          color: $gray-4;

          &.active {
            color: $av-blue;
          }
        }

        &:not(:first-child)::before {
          position: absolute;
          content: "";
          top: calc(50% - 1px);
          left: 0;
          width: calc(50% - 2rem);
          height: 2px;
          background-color: $gray-4;
        }

        &:not(:last-child)::after {
          position: absolute;
          content: "";
          top: calc(50% - 1px);
          right: 0;
          width: calc(50% - 2rem);
          height: 2px;
          background-color: $gray-4;
        }
      }
    }

  }
}