.review-widget {
  width: 400px;
  border: 1px solid #B7C6CF;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  //max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0px;
  z-index: 500;
  padding: 20px;
  .point-type{
    margin-top: 20px;
    vertical-align:middle;
    color: #B7C6CF;
  }
  .point-text{
    margin-top: 10px;
    vertical-align:middle;
    color: #B7C6CF;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
  .top-row{
    .close-button {
      float: right;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 767px){
    box-shadow: 0px 0px 0px 0px;
    border: none;
    padding: 10px 15px;
    height: 600px;
    width: 350px;
    background: white;
}

  .head-label {
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  }
  .link {
    color: #0085CB;
    font-size: 14px;
    text-decoration: underline;
  }

  ul{
    li{
      font-family: "Open Sans";
      font-size: 12px;
      cursor: pointer;
      display: inline-block;
      &.state{
        position: relative;
        margin: 0 13px;
        &:after {
          content: "";
          position: absolute;
          left: -20px;
          width: 14px;
          height: 14px;
          border: 3px solid #1286c8;
          border-radius: 50px;
          top: 4px;
        }

        &.unSelect {
          &:after {
            background-color: transparent;
            border: 1px solid #1286c8;
          }
        }
      }
      &.state-square {
        display: inline-block;
        padding: 5px 0;
        .fake-checkbox {
          margin-right: 5px;
        }
      }
    }
  }

  .list-unstyled {
    display: inline-block;
    padding-left: unset;
  }

}

@media(max-width: 767px) {
  .review-widget {
    // margin-top: 0;
    // margin-bottom: 70px;
  }
}
