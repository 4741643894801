/* This stylesheet generated by Transfonter (http://transfonter.org) on February 27, 2016 10:39 PM */

@font-face {
  font-family: "American Typewriter";
  src: url("AmericanTypewriter.eot");
  src: url("AmericanTypewriter.eot?#iefix") format("embedded-opentype"),
    url("AmericanTypewriter.woff") format("woff"),
    url("AmericanTypewriter.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
