@import 'css/_variables.scss';

.student-comments-modal {
  .modal-dialog {
    width: 800px;
  }

  .modal-body {
    padding: 2rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
        color: $av-blue;
      }
    }
  }
}

.resolve-confirmation-modal {
  .modal-dialog {
    width: 500px;
  }
}
