@import 'css/_variables.scss';

.user-status-container {
  &.relative {
    position: relative;
  }

  &.absolute {
    position: absolute;
    right: 8px;
    bottom: 0;
  }

  .user-status {
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    border: 2px solid $av-white;
    background-color: $av-green;

    &.online {
      background-color: $av-green;
    }

    &.warning {
      background-color: $av-camel;
    }

    &.offline {
      background-color: $av-red;
    }

    > .popover {
      left: -10px !important;

      > .arrow {
        margin-left: 0;
      }
    }

    .user-status-row {
      &:hover {
        color: black;
      }
    }

    .row.user-status-row {
      cursor: pointer;
    }

    .row:not(.user-status-row) {
      cursor: default;
    }

    .extension-status-title {
      display: flex;
      align-items: center;
    }
  }

  .division {
    width: 100%;
    height: 1px;
    background-color: $gray-2;
    margin: 4px 0 8px;
  }
}
