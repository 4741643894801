@import 'css/_variables.scss';

.progress-bar-transparent-layer {
  background-color: rgba($color: #000000, $alpha: 0.5);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1003;
}

.progress-bar {
  background-color: $av-light-blue;
  border-radius: 4px;
  color: $av-blue;
  cursor: pointer;
  font-weight: 600;
  min-width: 46px;
  padding: 4px 10px 8px;
  max-width: 200px;

  &.active {
    background-color: $av-blue;
    color: $av-white;

    .steps {
      .step {
        background-color: rgba($color: $av-medium-blue, $alpha: 0.3);

        &.checked {
          background-color: $av-white;
        }
      }
    }
  }

  .steps {
    margin-top: 8px;

    .step {
      background-color: $av-medium-blue;
      border-radius: 1px;
      height: 4px;

      &:not(:last-child) {
        margin-right: 2px;
      }

      &.checked {
        background-color: $av-blue;
      }
    }
  }
}

.progress-bar-popup {
  background: #ffffff;
  border-radius: 16px;
  left: 50%;
  padding: 32px;
  top: 100px;
  transform: translateX(-50%);
  width: 335px;
  z-index: 1003;

  .title {
    color: $av-blue;
    font-weight: bold;
    font-size: 22px;
  }

  .close {
    right: 32px;
    top: 40px;
    opacity: 1;
  }

  .description {
    margin-top: 17px;
  }

  .step {
    margin-top: 16px;
    color: $av-new-black;

    .check {
      background-color: $av-light-blue;
      border-radius: 12px;
      height: 24px;
      margin-right: 12px;
      width: 24px;
    }

    &.checked {
      .check {
        background-color: $av-green;
      }

      .text {
        color: $gray-3;
        text-decoration: line-through;
      }
    }
  }

  .triangle {
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid $av-white;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.progress-bar-summary-popup {
  background: #ffffff;
  border-radius: 16px;
  left: 50%;
  padding: 32px;
  top: 100px;
  transform: translateX(-50%);
  width: 335px;
  z-index: 1003;

  .title {
    color: $av-new-black;
    font-size: 16px;
  }

  .close {
    right: 20px;
    top: 35px;
    opacity: 1;
  }

  .description {
    margin-top: 17px;
  }

  .actions {
    margin-top: 23px;

    .action-link {
      color: $av-blue;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .triangle {
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid $av-white;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 768px) {
  .progress-bar-popup {
    .title {
      font-weight: 600;
      font-size: 14px;
    }

    .close {
      top: 34px;
    }

    .description {
      font-size: 12px;
    }

    .step {
      font-size: 12px;
    }
  }
}
