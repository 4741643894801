@import "css/_variables.scss";

.video-container {
  height: 300px;
  margin-bottom: 1rem;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.simple-step{
  .button-row{
    width: 90%;
    margin-left: 5%;
    //border: 1px solid yellow;
    .next-step-button{
      display: inline-block;
      width: 70%;
      margin-left: 15%;
      //border: 1px solid blue;
    }
    .skip-link{
      display: inline-block;
      color: red;
      text-decoration: underline;
      font-size: 12px;
      cursor: pointer;
      margin-left: 0%;
      //border: 1px solid green;
      width: 15%;
    }
  }
}

.walkthrough-modal-backdrop{
  background-color: #ccc !important;
}

.walkthrough-modal-backdrop.in{
  filter: alpha(opacity=50) !important;
  opacity: .2 !important;
}

// .modal-backdrop {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 1040;
//   background-color: red;
// }

// .modal-backdrop.in {
//   filter: alpha(opacity=50);
//   opacity: .5;
// }

.walkthrough-modal-header {
  border-bottom: 1px solid #ccdae2;
  padding: 17px 0;
  background: $av-blue;

  .btn-close {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .icon-close {
    color: #b7c7d0;
    margin: 0;
  }
  .walkthrough-modal-title {
    //color: #1286c8;
    color: white; 
    font-size: 14px;
    font-weight: 800;
    line-height: 1.21;
    text-align: center;
  }
}