@import 'css/_variables.scss';

.delete-confirmation-modal {

  .modal-body {
    padding: 2rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
        color: $av-blue;
      }
    }
  }
}
