@import 'css/_variables.scss';

.badge-details-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
        color: $av-blue;
      }
    }

    .subscription-container {
      text-align: center;

      .image-preview {
        height: 30rem;
      }

      .error-message {
        color: $av-red,
      }
    }

    .submit-container {
      text-align: end;
    }
  }
}
