@import "css/_variables.scss";

.add-to-collection-wrapper {
  margin: 0 auto;
  margin-top: 20px;
  max-width: 400px;
  .point-container{
    pointer-events : none;
    padding: 0 20px;
  }
  .row {
    margin-bottom: 13px;
    .btn-link {
      text-decoration: none;
      padding-right: 0;
    }
  }

  .none-selected{
    margin-left: 10px;
    color: $av-red;
    font-weight: normal;
  }

  .new-collection-link{
    float: right;
    margin-top: -20px;
    .icon-arrow-backward{
      color: $av-red;
    }
  }
  .selected-collection{
    .collection-name{
      margin-left: 10px;
      color: $av-blue;
    }
    .change-collection{
      margin-left: 5px;
      color: $av-red;
    }
  }

  .collections-list {
    .collection-list-item {
      align-items: center;
      border-radius: 10px;
      border: solid 1px $av-grayish-cyan;
      color: $av-grayish-cyan;
      cursor: pointer;
      //display: flex;
      display: inline-block;
      //width: 350px;
      //width: 330px;
      width: 280px;
      font-size: 12px;
      font-weight: 600;
      height: 34px;
      justify-content: space-between;
      margin-left: 20px;
      margin-bottom: 10px;
      padding: 0 10px;
      .collection-name{
        padding-top: 10px;
        float: left;
      }
      .visibility-icon{
        padding-top: 6px;
        float: right;
      }
      &:hover {
        border-color: $av-blue;
        color: $av-blue;
      }
      &.selected {
        //background-color: #1286c8; - Makes selected blue
        //color: #fff; - Makes selected blue
        border-color: $av-blue;
        color: $av-blue;
      }
    }
    .remove-collection{
      color: $av-red;
      float: right;
      font-size: 20px;
      cursor: pointer;
    }
  }
}
.add-to-collection{
  &.active{
    background:$av-white;
    .modal-dialog, .modal-content, .modalBody {
    height:100%;
    width : 100%;
    margin : 0px;
    }
  }
}