@import 'css/_variables.scss';

.reading-source-logo {
  .image-container {
    position: relative;
    margin-right: 0.75rem;

    img {
      width: 22px;
      height: 22px;
    }

    .status-check,
    .averpoint-logo {
      position: absolute;
      bottom: -5px;
      right: -5px;
      width: 12px;
      height: 12px;
      padding: 1px;
      border-radius: 3px;
      border: 1px solid $av-white;
      display: flex;
      align-items: center;
      justify-content: center;

      &.yellow {
        background-color: $av-gold;
      }

      &.green {
        background-color: $av-green;
        color: $av-white;
      }

      &.red {
        background-color: $av-red;
        color: $av-white;
      }
    }

    .averpoint-logo {
      bottom: -6px;
      right: 6px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }

    .source-name {
      position: absolute;
      text-wrap: nowrap;
    }

    [tooltip]::after {
      min-width: 200px;
    }
  }
}
