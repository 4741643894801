@import 'css/_variables.scss';
.create-point-entry-form {
  outline: none;
  background-color: $av-white;

  .collection-name,
  .invite-authors {
    cursor: pointer;
  }

  .create-new-collection {
    input {
      outline: none !important;
      border: 1px solid $gray-2;
      border-radius: 4px;
      height: 40px;

      &::placeholder {
        color: $gray-3;
      }
    }

    .privacy-icon {
      border: 2px solid transparent;
      height: 33px;
      width: 33px;
      background-color: $gray-1;
      border-radius: 4px;

      &.selected {
        color: $av-blue;
        border-color: $av-blue;
      }
    }

    .btn {
      border-radius: 4px;
      font-weight: normal;
      height: 33px;

      &.btn-create {
        background-color: $av-blue;
        color: $av-white;
        border: 2px solid $av-blue;

        .icon {
          path {
            stroke-width: 1px;
          }
        }
      }

      &.btn-close {
        color: $av-red;
        background-color: $gray-1;
        border: none;
      }
    }
  }

  .btn-submit {
    background: linear-gradient(
      288.08deg,
      #1286c8 0%,
      #2bb5e0 99.25%
    ) !important;
    border: none;

    &:disabled {
      background: $gray-2 !important;
    }
  }

  .editor {
    .custom-toolbar {
      position: absolute;
      bottom: -51px;
      left: 125px;
      z-index: 1;
    }
  }

  .lightweight-stance {
    .action-link {
      color: $gray-4;
      font-size: 12px;
      font-weight: bold;
      padding: 6px 4px;

      &.active {
        box-shadow: inset 0px -2px 0px #1286c8;
        color: $av-black;
      }
    }
  }

  .point-edit-controls {
    margin-top: 10px;
  }
}
