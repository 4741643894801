.dashboard {
  width: calc(100% - 160px);
  margin-left: 160px;

  &.no-sidebar {
    margin-top: 100px;
    width: 100%;
    margin-left: 0px;
  }
}

.popover {
  &.popover-users-role-filter,
  &.popover-users-course-filter {
    font-size: 12px;

    .popover-content {
      padding: 0;
      max-height: 400px;
      overflow-y: auto;

      .checkbox {
        .av-checkbox {
          padding-left: 0;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.popover-users-role-filter {
    min-width: 200px !important;
    width: 200px;
  }

  &.popover-users-course-filter {
    min-width: 350px !important;
    width: 350px;
  }
}
