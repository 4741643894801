@import 'css/_variables.scss';

.manage-classroom-groups-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .configure-visibility-container {
      margin-bottom: 2rem;

      .configure-visibility {
        font-size: 14px;
        margin-top: 3rem;

        &.default-group {
          margin-top: 0;
        }
      }

      .configure-visibility-subtitle {
        font-size: 12px;
      }

      .feature-dropdown-label {
        width: 175px;
      }

      .feature-dropdown {
        margin: 0 0 0 1rem !important;
        height: auto;
        width: 280px;

        label {
          display: none;
        }
      }
    }

  }
}