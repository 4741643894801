@import 'css/_variables.scss';

.badges-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
        color: $av-blue;
      }
    }

    .text {
      margin: 1rem 0;

      .link {
        color: $av-blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .badges {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 0.5rem 0.5rem;
      margin-top: 1rem;
      // pointer-events: none;

      &.inactive {
        opacity: 0.5;
      }

      .badge-container {
        width: 100%;
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
        }

        .badge-count {
          width: 2rem;
          height: 2rem;
          background-color: $nickel;
          font-size: 1.5rem;
          position: absolute;
          top: 1.2rem;
          right: 1.2rem;
          border-radius: 50%;
          color: white;
          text-align: center;
          line-height: 1.8rem;
          font-weight: 600;
        }

        .badge-name {
          font-size: 1rem;
          color: $av-blue;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
}
