@import 'css/_variables.scss';

.invitePoint {
  font-family: OpenSans;

  .modal-header {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 0 0 #ccdae2;
    color: #1286c8;
    text-align: center;
    border: none;
  }

  .invite-users {
    .selected-container {
      border-bottom: 1px solid $av-grayish-cyan;

      input {
        outline: none;
      }

      .user {
        border: 1px solid $av-grayish-cyan;
        border-radius: 12px;

        .icon-close {
          cursor: pointer;
        }
      }
    }

    .user-list {
      width: 100%;
      background: $av-white;
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid $av-grayish-cyan;
      border-radius: 2px;
      z-index: 1;

      .user {
        cursor: pointer;

        .checkbox {
          display: inline-block;
          margin: 0;

          label {
            padding: 0;
          }
        }

        &:hover {
          background-color: $av-light-gray;
        }
      }
    }
  }
}
