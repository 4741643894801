.create-collection-wrapper {
  // margin: 0 auto;
  // max-width: 400px;
  .help-text {
    color: #b7c7d0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.21;
  }
  .collection-visibility-btns {
    padding-left: 10px;
    .btn {
      margin-right: 10px;
      .icon-lock {
        width: 14px;
      }
    }
  }
  .tags-container {
    padding-left: 10px;
  }
  .react-tags__search {
    display: inline-table;
  }
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
