@import 'css/_variables.scss';

.log-reading-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
        color: $av-blue;
      }
    }

    .body {
      background: $gray-1;
      border: 1px solid $gray-2;
      border-radius: 0.5rem;
      margin-top: 1rem;
      padding: 1rem 2rem;

      .input-container {
        .floating-input {
          width: 78%;
          display: inline-block;
          vertical-align: middle;
          margin-right: 2%;
        }

        .rectangular-button {
          width: 20%;
          display: inline-block;
          vertical-align: middle;
        }
      }

      .source-container {
        .floating-input {
          width: 49%;
          display: inline-block;
          vertical-align: middle;
        }

        .floating-input:nth-child(1) {
          margin-right: 2%;
        }
      }

      .image-container {
        border: 1px solid $gray-2;
      }
    }

    .section {
      .title {
        font-weight: bold;
        color: $gray-3;
      }

      .multi-chip-component {
        .chip-container {
          margin-top: 1rem;
          display: inline-block;
          vertical-align: middle;
          margin-right: 1rem;

          .chip {
            padding: 1rem 1rem;
            background-color: $gray-2;
            border-radius: 5px;
            font-weight: bold;
            display: inline-flex;
            align-items: center;

            &.add {
              width: 125px;
            }

            &.topic-input {
              width: 200px;
            }

            &.location-input {
              width: 150px;
            }

            .floating-input {
              width: 100%;
              margin-bottom: 0;
              height: 2rem;

              input {
                padding: 0;
                color: $gray-3;
              }
            }

            .chip-value {
              flex-grow: 1;
            }

            .icon {
              margin-left: 1rem;
              color: $av-blue;
              flex-shrink: 0;
              cursor: pointer;
            }
          }

          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $gray-3;
            opacity: 1;
            /* Firefox */
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $gray-3;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $gray-3;
          }
        }
      }

      .level-container {
        margin-top: 1rem;
        margin-bottom: 1rem;

        .level {
          width: 32%;
          padding: 1rem 0;
          border: 1px solid $av-blue;
          font-weight: bold;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          color: $av-blue;
          border-radius: 5px;
          margin-left: 0;
          cursor: pointer;

          &.active {
            background-color: $av-blue;
            color: white;
          }

          &:hover {
            background-color: $av-blue;
            color: white;
          }

          &:nth-of-type(1),
          &:nth-of-type(2) {
            margin-right: 2%;
          }
        }
      }
    }

    .actions {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .next-button {
        background: $av-blue;
        padding: 0.5rem 2rem;
        color: white;
      }

      .back-button {
        border-color: transparent;
        padding: 0.5rem 2rem;

        &:hover {
          background: transparent;
          color: $av-blue;
        }
      }
    }

    .add-question-button {
      // background: $av-dark-blue;
      padding: 1.25rem 3rem;
      color: $av-blue;
      border-color: transparent;
      margin-right: 1rem;
      font-weight: bold;
    }
  }
}