.manage-source-platform-mappings {
  .manage-source-platform-mappings-label {
    font-weight: bold;
  }

  .manage-platform-mappings {
    display: flex;
    align-items: center;

    .manage-platform-mapping {
      margin-top: 1rem;
      width: 20rem;
      height: 45px;
      margin-bottom: 0;
    }
  }

  .rectangular-button {
    padding-left: 0;
    margin-top: 1rem;
  }
}
