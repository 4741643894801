@import 'css/_variables.scss';

.qtp-rtp-groups-selection {
  .btn-sounds-good {
    border: 1px solid $av-blue;
    font-size: 14px;
    font-weight: 600;
    width: 192px;
    height: 32px;

    .content {
      justify-content: center;
    }
  }

  .action-link {
    text-decoration: underline;
  }

  .btn-group {
    border: 1px solid $av-blue;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 600;
    height: 30px;
    margin-right: 10px;
    margin-bottom: 10px;

    &.selected {
      background-color: $av-blue;
      color: $av-white;
    }
  }

  // .btn-notify {
  //   background-color: transparent;
  //   color: $av-blue;
  //   height: auto;
  //   padding-left: 0;
  //   padding-right: 0;
  // }

  .qtp-rtp-group-selection-footer {
    border-top: 1px solid #c0cfda;

    .action-link {
      .action-link-text {
        white-space: normal;
      }
    }
  }
  .groups-container {
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    height: 200px;
    overflow-y: auto;

    .group {
      cursor: pointer;
      padding: 5px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid #f1f1f1;
      }

      &.selected {
        background-color: #f1f1f1;
      }
    }
  }
}
