.glyphicon-removes {
  background: url('../assets/images/remove.svg');
  background-size: contain;
  height: 18px;
  width: 12.6px;
}

.glyphicon-thumb-down-small,
.glyphicon-thumb-up-small {
  background-size: contain;
  height: 14px;
  width: 14px;
}

.glyphicon-thumb-up-small {
  background: url('../assets/images/support-small.svg') no-repeat;
}

.glyphicon-thumb-down-small {
  background: url('../assets/images/oppose-small.svg') no-repeat;
  transform: translateY(2px);
}

.glyphicon-add-small {
  background: url('../assets/images/add-small.svg') no-repeat;
  background-size: contain;
  height: 13px;
  width: 12px;
}

.icon-verified-circle,
.icon-audience,
.icon-team,
.icon-awards,
.icon-money {
  color: white;
}
