@import 'css/_variables.scss';

.location-picker {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem;

  .label {
    color: #cccccc;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 0.5rem;
    position: absolute;
    top: -4px;
    left: 0;
    z-index: 1;
  }

  .show-address {
    margin-top: 0.5rem;
  }

  input[type='text'] {
    padding-left: 0 !important;
    border-bottom-width: 1px;
    padding: 2.5rem 0;

    &::placeholder {
      color: #8f8f8f;
      opacity: 1; /* Firefox */
    }

    &::-ms-input-placeholder {
      /* Edge 12 -18 */
      color: #8f8f8f;
    }
  }

  .selected-value {
    position: absolute;
    left: 0;
    top: 12px;
  }

  .cancel-icon {
    position: absolute;
    right: 6px;
    top: 24px;
    cursor: pointer;
  }

  .required-field {
    // color: $av-red;
    margin-left: 0.5rem;
  }
}
