@import 'css/_variables.scss';

.point-page-container {
  // background-color: $av-light-blue;

  .point-card {
    &.page {
      border-bottom: 1px solid #eaeaea;
      border-top: 1px solid #eaeaea;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .four-pack {
        // border-bottom: 1px solid $av-medium-blue;
        // margin-top: 33px;

        .btn {
          &.qtp,
          &.rtp {
            // height: 48px;
            // width: 151px;
            // margin-bottom: -1px;

            &.active {
              // background-color: white !important;
              // border-radius: 3px 3px 0px 0px;
              // border-left: 1px solid $av-medium-blue;
              // border-right: 1px solid $av-medium-blue;
              // border-top: 1px solid $av-medium-blue;
            }
          }
        }
      }
    }
  }

  .four-pack-tabs-connect {
    display: none;
  }

  .four-pack-tabs {
    .create-point-light-weight .import-point-link {
      right: 40px;
      top: 16px;
    }
  }
}
