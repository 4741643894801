@import 'css/_variables.scss';

.manage-quiz-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .prefill-button {
      border-color: transparent;

      &:hover {
        background-color: transparent;
        color: $av-blue;
      }
    }
  }
}
