@import 'css/_variables.scss';

.a-drop-down-with-action {
  height: 50px;
  position: relative;
  margin: 12px 0 20px;

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  label {
    color: #ccc;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    padding: 0;
    pointer-events: none;
    transition: 0.2s ease all;
    margin-bottom: 0;
  }

  > div > div:nth-of-type(2) {
    margin-top: 4rem;
  }

  .drop-down-search {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    background-color: white;
    border: 1px solid $av-gray;
    height: 4rem;
    position: relative;
    z-index: 2;

    input {
      border: 0 !important;
      outline: none !important;
      flex-grow: 1;
    }
  }

  .required-field {
    // color: $av-red;
    margin-left: 0.5rem;
  }

  @media (max-width: 767px) {
    margin: 0 0 10px;

    label {
      font-size: 12px;
    }
  }
}
