@import 'css/_variables.scss';

.edit-research-properties-objectives-modal {
  .modal-body {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .properties {
      .title {
        border-bottom: 1px solid $gray-3;
        font-weight: 500;
      }

      .value {
        border: 1px solid $av-medium-blue;
        border-radius: 4px;
        font-size: 12px;
        width: 150px;

        &.number {
          width: 100px;
        }

        &.datetime-local {
          width: 200px;
        }

        &.hours {
          width: 36px;
        }

        &.am-pm-picker {
          margin-left: 4px;
          width: 48px;
          padding-left: 4px;
          padding-right: 4px;
        }
      }
    }
  }
}