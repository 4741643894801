@import 'css/_variables.scss';

.async-auto-complete {
  position: relative;

  .floating-input {
    z-index: 99;
  }

  .async-auto-complete-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 98;
  }

  .options-container {
    position: absolute;
    top: 100%;
    background-color: $gray-1;
    width: 100%;
    font-weight: 500;
    box-shadow: 0px 2px 8px 1px $gray-2;
    z-index: 100;

    .option {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid $gray-2;
      cursor: pointer;
      display: flex;
      align-items: center;

      .source-icon-container {
        position: relative;

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 25px;
          height: 25px;
          background-color: white;
          opacity: 0.6;
        }
      }

      div {
        img {
          width: 25px;
          height: 25px;
          margin-right: 0.5rem;
        }
      }

      &:hover {
        background-color: $gray-2;
      }

      &.disabled {
        color: $gray-3;
      }

      &.disabled:hover {
        background-color: $gray-1;
      }
    }
  }

  .spinner {
    position: absolute;
    right: 10px;
    top: 20px;
  }
}
