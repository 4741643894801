@import 'css/_variables.scss';

.author-tree {
  border: 1px solid #b7c6cf;
  border-radius: 2px;

  .search-container {
    border-bottom: 1px solid $av-grayish-cyan;
    input {
      border: none;
      outline: none;
    }
  }

  .list-item {
    cursor: pointer;

    .checkbox-container {
      border: 1px solid $av-blue;
      border-radius: 3px;
      height: 16px;
      width: 16px;
      padding-top: 3px;
      padding-left: 3px;

      .icon-check {
        stroke-width: 2px;
      }
    }
  }
}
