@import 'css/_variables.scss';

.point-start-component {
  // padding-bottom: 70px;
  // .footer {
  //   // position: fixed;
  //   // left: 0;
  //   // bottom: 0;
  //   // width: 100%;
  //   background-color: white;
  //   border-top: 1px solid #eaeaea;
  //   color: white;
  //   text-align: center;
  //   // padding-top: 5px;
  //   // padding-bottom: 5px;
  //   height: auto;
  //   &.with-error {
  //     //height: 100px;
  //     height: 80px;
  //   }
  //   .error-message {
  //     // height: 25px;
  //     //border: 1px solid red;
  //   }
  // }
  .form-control {
    padding: 0 !important;
    //padding-left: 10px !important;
    font-family: 'Open Sans';
    font-size: 14px;
    color: #333333;
    &::-webkit-input-placeholder {
      color: #333333;
    }
  }
  .point-and-quote {
    // border: 1px solid #e6e6ed;
    // padding: 15px;
    // margin-bottom: 20px;
    // padding-bottom: 30px;
    // background-color: white;

    .source-quote {
      font-style: italic;
      max-height: 250px;
      overflow-y: scroll;
      overflow-wrap: break-word;
      padding: 5px 15px;
    }

    .formatted-source-footnote {
      padding: 0px 15px;
    }

    .edit-source-icon {
      color: red;
      margin-top: 3px;
      margin-left: 5px;
      cursor: pointer;
      float: right;
    }

    .source-name {
      font-style: italic;
    }
  }
  .form-control[type='text'] {
    border-color: #b7c7d0;
    border-width: 0 0 1px;
  }
  .form-control[type='date'] {
    border-color: #b7c7d0;
    border-radius: 0;
    border-width: 0 0 1px;
    box-shadow: none;
  }
  .point-quote-icon {
    text-align: center;
    .quote-icon {
      img {
        margin-top: -6px;
      }
    }
    .quote-label {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.21;
      color: #1286c8;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .point-quote {
    border-radius: 10px;
    //border: solid 1px #b7c7d0;
    border: none;
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    position: relative;

    textarea {
      border: none;
      box-shadow: none;
      padding: 0;
      resize: none;
      font-family: 'Open Sans';
      font-size: 12px;
      line-height: 1.5;
      text-align: left;
      color: #333333;
      overflow-y: auto;
    }
    .optional {
      color: #b7c7d0;
      font-size: 10px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    .quote-decription {
      //width: 340px;
      .more {
      }
      .less {
        max-height: 100px;
        margin-top: 15px;
      }
      .quote-content {
        font-style: italic;
        .description {
          //width: 340px;
          max-height: 100px;
          font-family: 'Open Sans';
          font-size: 12px;
          line-height: 1.5;
          text-align: left;
          color: #333333;
          overflow-y: auto;
          //: 45px;
          &.border-less {
            /* border-bottom: 1px solid #b7c6cf; */
            box-shadow: 0 0 20px 0 rgba(51, 51, 51, 0.1);
            border-radius: 5px;
            background-color: #ffffff;
          }
          &.not-allow {
            opacity: 0.8;
            cursor: not-allowed;
          }
        }
        .show {
          font-family: 'Open Sans';
          font-size: 10px;
          text-align: left;
          color: #1286c8;
          border-top: 1px solid rgba(51, 51, 51, 0.1);
          padding-top: 6px;
          cursor: pointer;
          &.more {
          }
          &.less {
          }
        }
      }
      .close-icon {
        float: right;
        margin-top: -27px;
        display: block;
      }
      .optional {
        right: 4px;
        top: 19px;
      }
      .user {
        position: relative;
        .username {
          background-image: url('../icons/user.svg');
          background-repeat: no-repeat;
          padding-left: 25px !important;
          background-size: 21px 32px;
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          line-height: 1.21;
          text-align: left;
          color: #1286c8;
          &::-webkit-input-placeholder {
            color: #1286c8;
          }
        }
      }
    }
    .close-icon-desc {
      position: absolute;
      top: 20px;
      right: 12px;
    }
    &:before {
      display: none;
      // color: #b7c7d0;
      // //content: '"';
      // font-size: 62px;
      // font-style: italic;
      // left: 28px;
      // line-height: 1;
      // position: absolute;
      // top: -43px;
      // transform: rotate(180deg);
    }
  }
  // .help-text {
  // color: #b7c7d0;
  // font-size: 14px;
  // font-weight: 600;
  // line-height: 1.21;
  // margin-top: 20px;
  //  }

  // .privacyDiv {
  //   // margin-top:20px;

  //   .status-btns {
  //     padding-left: 10px;
  //     .btn {
  //       margin-right: 10px;
  //       .icon-lock {
  //         width: 14px;
  //       }
  //     }
  //   }
  // }
  .source-info-container {
    //background-color: #e6e6ed;
    // padding: 5px;
    .top-row {
      .close-button {
        cursor: pointer;
        float: right;
      }
      height: 30px;
    }

    .url-form-group {
      position: relative;
      .fa-spin {
        position: absolute;
        right: 9px;
        top: 9px;
        z-index: 100;
      }
    }
  }

  .fact-or-opinion {
    margin-top: 20px;
    .tags-container {
      .tag {
        width: 31.33%;
        text-align: center;
      }
    }
    &.devider {
      border-bottom: solid 1.3px #b7c6cf;
    }
  }
  .type,
  .againstType {
    .selection-container {
      .tag-item {
        width: 31.33%;
      }
    }
  }
  .button-row {
    //margin-bottom: 20px;
    //padding-top: 10px;

    .save-button {
      display: inline-block;
    }
    .back {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
      //line-height: 45px;
      text-align: center;
      width: 100px;
      //margin: 0px auto;
      margin-left: -100px;
      color: #b7c6cf;
      cursor: pointer;
    }
  }
  .quote-note {
    top: 25px;
    text-align: right;
    font-size: 10px;
    line-height: 1.5;
    position: absolute;
    right: 0;
    &.required {
      color: #f6511d;
    }
    &.required-valid {
      // color: #1df676;
      display: none;
    }
    &.from-url {
      color: #7fb800;
    }
    &.optional {
      color: #b7c7d0;
    }
    &.top {
      top: 10px;
    }
  }
  textarea[disabled],
  input[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    background: white;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &.isExtension {
    @media (max-width: 767px) {
      .form-control {
        font-size: 14px;
      }
      .floating-input {
        input,
        textarea {
          font-size: 14px;
        }
        label {
          font-size: 14px;
        }
        input:focus ~ label,
        input:disabled ~ label,
        input:valid ~ label,
        textarea:focus ~ label,
        textarea:disabled ~ label,
        textarea:valid ~ label {
          font-size: 10px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .point-quote {
      .quote-decription {
        //width: 269px;
        .quote-content {
          .description {
            //width: 254px;
          }
        }
      }
    }
    .quote-decription {
      .close-icon {
        //display: none !important;
      }
      .optional {
        //right: 21px !important;
      }
    }
    .form-control {
      font-size: 12px;
    }
  }

  .privacy {
    .btn {
      border: 2px solid $av-blue !important;
      border-radius: 4px;
      height: 33px;
    }

    .not-selected {
      .btn {
        border-color: transparent !important;
        color: $av-gray;
        background-color: $gray-1;
      }
    }
  }
}
