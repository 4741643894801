@import 'css/_variables.scss';

.resolve-confirmation-modal {
  .modal-dialog {
    width: 500px;
  }

  .modal-body {
    padding: 2rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
        color: $av-blue;
      }
    }

    .courses-table-header {
      border-bottom: 1px solid $gray-2;
    }

    .source-title {
      font-size: 1.5rem;
    }

    .courses-title {
      font-size: 1.5rem;
    }

    .features {
      font-size: 1.5rem;
    }
  }
}