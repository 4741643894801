@import 'css/_variables.scss';

.multi-chip-component {
  .title {
    color: $av-gray;
  }

  .chip-container {
    margin-top: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;

    .chip {
      padding: 1rem 1rem;
      background-color: $gray-2;
      border-radius: 5px;
      font-weight: bold;
      display: inline-flex;
      align-items: center;

      &.add {
        width: 125px;
      }

      &.topic-input {
        width: 200px;
      }

      &.location-input {
        width: 150px;
      }

      .floating-input {
        width: 100%;
        margin-bottom: 0;
        height: 2rem;

        input {
          padding: 0;
          color: $gray-3;
        }
      }

      .chip-value {
        flex-grow: 1;
      }

      .icon {
        margin-left: 1rem;
        color: $av-blue;
        flex-shrink: 0;
        cursor: pointer;
      }
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $gray-3;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $gray-3;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $gray-3;
    }
  }
}