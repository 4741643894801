@import 'css/_variables.scss';

.rectangular-button {
  border: 1px solid $av-blue;
  border-radius: 5px;
  color: $av-blue;
  padding: 5px 10px;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &-active {
    background: $av-blue;
    color: $av-white;
  }

  // margin-left: 10px;
  // margin-bottom: 10px;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      margin-right: 6px;
    }
  }

  &.green {
    color: $av-green;
    border-color: $av-green;

    &:hover {
      background: $av-green;
      color: $av-white;

      svg {
        color: $av-white;
      }

      &.disabled {
        svg {
          color: $av-green;
        }
      }
    }
  }

  &.red {
    color: $av-red;
    border-color: $av-red;

    &:hover {
      background: $av-red;
      color: $av-white;

      svg {
        color: $av-white;
      }

      &.disabled {
        svg {
          color: $av-red;
        }
      }
    }
  }

  &.light-blue {
    background-color: #d2e6f5;
    color: $av-blue;

    &:hover {
      background-color: $av-blue;
      color: $av-white;
    }

    &.disabled,
    &.disabled:hover {
      background-color: #d2e6f5;
    }
  }

  &.blue {
    background-color: $av-blue;
    color: $av-white;

    &:hover {
      background-color: $av-white;
      color: $av-blue;
    }

    &.disabled,
    &.disabled:hover {
      background-color: $av-blue;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 3px 10px;
  }

  &:hover {
    background: $av-blue;
    color: $av-white;
  }

  &.disabled,
  &.disabled:hover {
    cursor: not-allowed;
    background: $av-white;
    border: 1px solid $av-grayish-cyan;
    color: $av-grayish-cyan;
  }

  &.active,
  &.active:hover {
    background: $av-light-blue;
  }

  &.small-button {
    //height: 30px;
    font-size: 12px; //h6
  }

  &.dark-button {
    background: $av-blue;
    color: $av-white !important;

    &-active {
      background: $av-dark-blue-active;
    }

    &:hover {
      background: $av-dark-blue-hover;
    }

    &.disabled {
      background: $av-gray;
    }
  }

  &.no-border {
    background: transparent !important;
    border-color: transparent !important;

    &-active {
      background: transparent !important;
      color: $av-dark-blue-active !important;

      &.red {
        color: $av-dark-red !important;
      }
    }

    &:hover {
      background: transparent !important;
      color: $av-dark-blue-hover !important;

      &.red {
        color: $av-dark-red !important;
      }
    }
  }

  &.hero-button {
    background: linear-gradient(288.08deg, #1286c8, #2bb5e0 99.25%) !important;
    color: white !important;

    &:hover {
      background: linear-gradient(
        288.08deg,
        #0c78b6,
        #1ba8d4 99.25%
      ) !important;
    }

    &:active {
      background: linear-gradient(
        288.08deg,
        #006299,
        #058eba 99.25%
      ) !important;
    }
  }

  &.light-button {
    background-color: $av-light-blue !important;
    color: $av-blue !important;
    border-color: $av-light-blue !important;

    &:hover {
      background-color: $av-light-blue-hover !important;
      border-color: $av-light-blue-hover !important;
    }

    &:active {
      background-color: $av-light-blue-active !important;
      border-color: $av-light-blue-active !important;
    }
  }

  &.white-button {
    background-color: $av-white;

    &:hover,
    &:active {
      background: $av-blue;
      color: $av-white;
    }
  }
}
