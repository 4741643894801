@import 'css/_variables.scss';

.source-picker {
  .header {
    padding: 0;
    margin: 2rem 0 0;
    max-width: 574px;

    .row {
      margin: 0px !important;

      * {
        padding: 0;
        font-size: 1.25rem;
        color: $av-blue;
      }
    }

    .paid-subscriber,
    .remove {
      text-align: center;
    }
  }

  .sources {
    padding: 0;
    margin: 1rem 0 1rem;
    max-width: 574px;

    .row {
      margin: 0px !important;

      * {
        padding: 0;
        color: $av-blue;
      }

      .source-title {
        display: flex;
        align-items: flex-start;

        .icon {
          flex-shrink: 0;
          margin-top: 4px;
        }

        .user-selected-source {
          flex-grow: 1;
        }
      }

      .paid-subscriber,
      .remove {
        text-align: center;
      }
    }
  }

  .user-selected-source {
    color: $av-blue;
    font-weight: 600;
  }
}
