@import 'css/_variables.scss';

.contact-button,
.contact-pane {
  position: fixed;
  bottom: 0px;
  right: 0px;

  // @media screen and (max-width:767px){
  //     bottom: 75px;
  // }

  button {
    background: $av-blue;
    color: white;
    .text {
      margin-right: 5px;
    }
  }
}

.contact-pane {
  z-index: 1000;
  background: white;
  border: 1px solid $av-blue;
  padding-bottom: 15px;
  max-width: 500px;
  max-height: 80%;
  overflow: scroll;
  .icon-arrow-down {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 30px;
  }
}

@media screen and (max-width: 767px) {
  body {
    &.bottom-nav {
      .contact-button,
      .contact-pane {
        bottom: 63px;
      }
    }
  }
}
