@import 'css/_variables.scss';

.pro-sidebar {
  height: 100vh;
  width: 160px;
  max-width: 160px;
  min-width: 160px !important;
  position: fixed;

  .pro-sidebar-inner {
    background-color: #1286c8;

    .pro-menu {
      padding: 8px 8px;

      .pro-menu-item {
        .pro-inner-item {
          border-radius: 20px;
          padding-left: 12px;
        }

        .pro-inner-list-item {
          background-color: #1286c8 !important;
        }

        .pro-icon-wrapper {
          background-color: #1286c8 !important;
          width: 24px;
          height: 24px;
          min-width: 24px;
          margin-right: 0.5rem;

          .pro-icon {
            color: #d5e7f4;
          }
        }

        .pro-item-content {
          color: #d5e7f4;
        }

        .pro-arrow-wrapper {
          color: #d5e7f4;

          .pro-arrow {
            border-color: #d5e7f4 !important;
          }
        }

        &.active {
          color: $av-blue;

          .pro-inner-item {
            background-color: #d5e7f4 !important;
          }

          .pro-inner-list-item {
            background-color: #d5e7f4 !important;
          }

          .pro-icon-wrapper {
            background-color: #d5e7f4 !important;

            .pro-icon {
              color: #1286c8;
            }
          }

          .pro-item-content {
            color: #1286c8;
          }

          .pro-arrow-wrapper {
            color: #1286c8;

            .pro-arrow {
              border-color: #1286c8 !important;
            }
          }
        }

        &.sub-menu-active {
          color: $av-blue;

          > .pro-inner-item {
            background-color: #d5e7f4 !important;
          }

          > .pro-inner-item {
            .pro-item-content {
              color: #1286c8;
            }
          }

          .pro-icon-wrapper {
            background-color: #d5e7f4 !important;

            .pro-icon {
              color: #1286c8;
            }
          }

          .pro-item-content.active {
            color: #1286c8;
          }

          .pro-arrow-wrapper {
            color: #1286c8;

            .pro-arrow {
              border-color: #1286c8 !important;
            }
          }
        }
      }
    }
  }

  .pro-sub-menu {
    .pro-inner-list-item {
      > div > ul {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .pro-menu-item {
          background-color: $av-blue !important;
          color: $av-light-blue-active !important;

          .pro-inner-item {
            padding: 3px 8px !important;
            font-size: 12px;
            background-color: $av-blue !important;
            color: $av-light-blue-active !important;

            .pro-item-content {
              background-color: $av-blue !important;
              color: $av-light-blue-active !important;
            }
          }

          &.active {
            background-color: $av-blue !important;

            > .pro-inner-item {
              background-color: $av-blue !important;

              .pro-item-content {
                color: $av-light-blue !important;
                background-color: $av-blue !important;
                font-weight: 600 !important;
              }
            }

            .pro-icon-wrapper {
              background-color: $av-blue !important;

              .pro-icon {
                color: $av-light-blue !important;
              }
            }

            .pro-item-content.active {
              color: $av-light-blue !important;
            }

            .pro-arrow-wrapper {
              color: $av-light-blue !important;

              .pro-arrow {
                border-color: $av-light-blue !important;
              }
            }
          }

          &:hover {
            color: $av-light-blue !important;

            .pro-inner-item {
              color: $av-light-blue !important;

              .pro-item-content {
                color: $av-light-blue !important;
                font-weight: 600 !important;
              }
            }
          }
        }
      }
    }
  }

  .separator {
    height: 1px;
    width: calc(100% - 1rem);
    background-color: $av-dark-blue;
    margin: 0.5rem;
  }
}
