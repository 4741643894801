@import 'css/_variables.scss';

.vertical-modal-header {
  border-bottom: 1px solid #ccdae2;
  padding: 17px 0;
  background: $av-blue;

  .btn-close {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .icon-close {
    color: #b7c7d0;
    margin: 0;
  }
  .vertical-modal-title {
    //color: #1286c8;
    color: white;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.21;
    text-align: center;
  }
}

.main-content {
  // width: 550px;
  word-wrap: normal;
  .options {
    .option {
      margin-right: 20px;
      padding: 10px;
      width: 160px;
      display: flex;
      align-items: center;
      border-color: $av-gray;
      color: $av-gray;
      .icon {
        margin-right: 10px;
      }
      &:hover {
        background: $av-grayish-cyan;
        color: $av-white;
      }
    }
  }
}
