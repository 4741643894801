.inviteAverpoint{
	  font-family: "Open Sans";
	 .modal-header{
	 	border-radius: 5px;
		background-color: #ffffff;
  		box-shadow: 0 1px 0 0 #ccdae2;
  		color: #1286c8;
  		text-align:center;
  		border:none;
	 }
	 .user-list-item {
      align-items: center;
      border-radius: 10px;
      border: solid 1px #b7c7d0;
      color: #b7c7d0;
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: 600;
      height: 34px;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 0 10px;
      &:hover {
        border-color: #1286c8;
        color: #1286c8;
      }
      &.selected {
        background-color: #1286c8;
        border-color: #1286c8;
        color: #fff;
      }
    }
	 input:focus{
	 	outline:none;
	 }
	.header{
		display:inline-block;
		font-size: 14px;
		font-weight: 600;

	}
	.fa-times{
		color: #b7c7d0;
		font-size:16px;
	}
	.modal-body{
		padding : 0 70px;
	}
	.inviteByEmail{
		margin-top:5px;
		.inviteInput{
			width:100%;
			border:0px none;
			border-bottom: 1px solid #b7c6cf;
			line-height:3;
			margin-bottom:20px;
		}
		::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		    color:#000;
		}
		:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		   color:#000;
		   opacity:1;
		}
		::-moz-placeholder { /* Mozilla Firefox 19+ */
		   color:#000;
		   opacity:1;
		}
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
		   color:#000;
		}
		::-ms-input-placeholder { /* Microsoft Edge */
		   color:#000;
		}
	}
	.share-link-div{
		position:relative;
		.copy-link{
			position: absolute;
		    right: 20px;
		    top: 25px;
		    font-size: 14px;
  			color: #1286c8;
		}
	}
	.share-via-link{
		color: #333333;
		font-size: 14px;
		line-height: 1.5;
	}
	.previewTitle{
		font-weight:bold;
		font-size: 14px;
	  	line-height: 1.5;
		text-align: left;
		color: #333333;
	}
	.sendButton{
	    text-align: center;
    	margin: 20px;
    	.postTo{
    		font-size: 14px;
		  	text-align: center;
  			color: #b7c7d0;
  			margin-top:20px;
  			margin-bottom:15px;
    	}
    	button{
    		min-width:120px;
    		height:40px;
    	}
    	.shareBtnTxt{
    		  font-size: 12px;
			  font-weight: 600;
    	}
    	.fa{
    		margin-right:10px;
    	}
    	.send-button{
    		color: #1286c8;
    		border: solid 1.5px #1286c8;
    		font-size: 14px;
  			font-weight: 600;
    	}
	}
	.point-input {
	      border: none;
	      border-bottom: 1px solid #EEEEEE;
	      box-shadow: none;
	      border-radius: 0;
	      display: inline-block;
	      width: 100%;
	      height: 34px;
	      padding: 6px 12px;
	      font-size: 14px;
	      line-height: 1.42857;
	      color: #555555;
	      background-color: #fff;
	      margin-right: 5px;
	      padding-right:50px;
	      text-align: left;

	      &:focus {
	        border-bottom:1px solid #1286c8;
	        outline: none;
	    }
	}
	.bottom-button{
		text-align:right;
		margin:10px;
	}
	.done-button{
		margin-left:10px;
	}
	.btn{
		font-family: "Open Sans";
	  	font-size: 12px;
	  	font-weight: 600;
	  	text-align: center;
	  	border-radius:20px;
	  	height: 25px;
	}
	.btn-cancel{
		color: #979797;
		border: solid 1px #979797;	
	}
	.btn-done{
		color: #1286c8;
		border: solid 1px #1286c8;
	}
	.embed{
		text-align:center;
		.desc{
			font-size: 14px;
			color: #b7c7d0;
			margin-bottom: 20px;
			text-align:center;
		}
		.embedDiv{
			text-align:center;
			margin-top:20px;
			margin-bottom:35px;
			.embed-button{
				height:40px;
				width:220px;
				border: solid 1.5px #1286c8;
				color:#1286c8;
				font-size: 14px;
			}
		}
		.wrapper {
				border: solid 1.5px #1286c8;
    			display:inline-block;
    			border-radius:25px;
    			width:290px;
    			input{
				    height: 37px;
				    border-radius: 25px;
				    border-top-right-radius: 0;
				    border-bottom-right-radius: 0;
				    border: none;
    			}
			.embed-button{
				border-top-left-radius: 0px;
    			border-bottom-left-radius: 0px;
    			width:70px;
    			color :#fff;
    			height:40px;
    			background-color:#1286c8;
    			float:right
			}
		}
	}
}
#custom-search-input {
    margin:0;
    margin-top: 10px;
    padding: 0; 
    .input-group{
    	width:100%;
    	.input-group-btn{
    		right:36px;
    	}
    }
	.search-query {
	    padding-right: 3px;
	    padding-left: 10px;

	    margin-bottom: 0;
	    -webkit-border-radius: 10px;
	    -moz-border-radius: 10px;
	    border-radius: 10px;
	    width: 100%;
	}

	#custom-search-input button {
	    border: 0;
	    background: none;
	    /** belows styles are working good */
	    padding: 2px 5px;
	    margin-top: 2px;
	    position: relative;
	    left: -28px;
	    /* IE7-8 doesn't have border-radius, so don't indent the padding */
	    margin-bottom: 0;
	    -webkit-border-radius: 3px;
	    -moz-border-radius: 3px;
	    border-radius: 3px;
	    color:#b7c6cf;
	}
	.search-query:focus + button {
	    z-index: 3;   
	}
}