@import 'css/_variables.scss';

.editor {
  .custom-editor {
    .public-DraftStyleDefault-block,
    .public-DraftStyleDefault-ol,
    .public-DraftStyleDefault-ul {
      margin: 0;
    }
  }

  .custom-toolbar {
    border: none;
    padding: 0;
    // display: none;

    .toolbar-btn {
      border: 1px solid $gray-2;
      box-sizing: border-box;
      border-radius: 3px;
      width: 24px;
      height: 24px;

      &.rdw-option-active {
        border: none;
        background: $av-blue;
        color: white;
      }
    }
  }

  &.focused {
    .custom-toolbar {
      display: flex;
    }
  }

  .rdw-image-imagewrapper {
    img {
      max-width: 100%;
    }
  }
}
