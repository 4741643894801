@import 'css/_variables.scss';

.success {
  background-color: white;
  .heading {
    font-size: 14px;
    color: $av-gray;
  }
  .link {
    color: $av-blue;
    cursor: pointer;
  }
  .copy-button-section {
    margin-top: 10px;
    .copy-button {
      font-size: 12px;
      padding: 3px 5px;
      background-color: $av-blue;
      color: white;
    }
  }
}

.icon-check {
  stroke-width: 2px;
}
