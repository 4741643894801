.review-details {
  .review-head {
    color: #333333;
    font-size: 16px;
    line-height: 30px;
    border-bottom: 1px solid #B7C6CF;
    @media screen and (max-width: 767px){
    }
  }

  .user-review{
    border-radius: 5px;
    // border: solid 1px #b7c7d0;
  }

  .send-btn {
    border: 1px solid #0085CB;
    border-radius: 5px;
    color: #0085CB;
    padding: 5px 8px;
    display: inline-block;
    @media screen and (max-width: 767px){
      margin-top: 5px;
    }
    margin-bottom: 10px;
    font-weight: bold;
    cursor: pointer;
    &.submit {
      width: 100%;
      text-align: center;
      font-weight: 600;
    }
  }

  .head-title{
    .help-icon{
      margin-left: 3px;
    }
  }
}