@import 'css/_variables.scss';

.point-card-header {
  width: 100%;
  //border-bottom: 1px solid #E7DFDD;

  .top-section {
    background-color: $av-blue;

    svg {
      color: $av-white;
    }
    .card-title {
      font-weight: 400;

      .point-label {
        //color: #1286c8;
        color: $av-white;
      }

      .time-updated {
        color: $av-grayish-cyan;
        opacity: 0.5;
        font-weight: normal;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }
    .status {
      color: $av-grayish-cyan;
    }
  }

  .point-profilepic {
    //background-color: #E7DFDD;
    padding-left: 0px;
    font-weight: 400;

    .profile-image {
      margin-right: 5px;
    }
    .user-name {
      display: inline-block;
      color: $av-white;
    }
    //float: left;
  }
}
