@import 'css/_variables.scss';

.point-edit-container {
  .add-link {
    margin-right: 10px;
  }
  .editMessage {
    float: right;
  }
  .point-edit-messages {
    display: flex;
    justify-content: center;
    .error {
      color: $av-red;
    }
  }
}
