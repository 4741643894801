@import 'css/_variables.scss';

.quiz-question {
  // margin: 1rem 0;

  .question-text {
    font-weight: 400;
    // font-size: 1.5rem;
    font-size: 12px;
    color: $av-black;
    // padding: 1rem;
    // border-left: 0.5rem solid $av-blue;
  }
  .question-feedback {
    margin: 1rem 0;

    &.correct {
      color: $av-green;
    }

    &.wrong {
      color: $av-red;
    }
  }
  .question-options {
    // margin: 1rem 1.5rem;
    // font-size: 1.25rem;
    font-size: 12px;
    color: $gray-4;

    .question-option {
      border: 1px solid $gray-2;
      padding: 0.75rem;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      background-color: $av-white;
      border-radius: 4px;
      cursor: pointer;

      &.selected {
        border: 1px solid $av-blue;
        color: $av-blue;
      }
      &.wrong {
        border: 1px solid $av-red;
        color: $av-red;
      }
      &.correct {
        border: 1px solid $av-green;
        color: $av-green;
      }

      .option-number {
        background-color: $gray-3;
        color: white;
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 50%;
        margin: 0 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &.selected {
          background-color: $av-blue;
        }
        &.wrong {
          background-color: $av-red;
        }
        &.correct {
          background-color: $av-green;
        }
      }

      &:hover {
        border: 1px solid $av-blue;

        .option-number {
          background-color: $av-blue;
        }
      }
    }
  }

  .form-control.question-text-input {
    border: 1px solid $gray-2;
    border-width: 1px;
    font-size: 12px;
    border-radius: 4px;
    min-height: 150px;
  }
}
