@import 'css/_variables.scss';

.footer {
  position: relative;
  z-index: 1000;
  background-color: #1571bc;
  color: white;
  padding-bottom: 70px;

  a {
    color: white;
    &:hover {
      color: white;
    }
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 70px;
  }
}
