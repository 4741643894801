@import "css/_variables.scss";

.editable-text:focus {
  outline: 0px solid transparent;
  padding-left: 1px;
  background-color: $av-white;
}

.edit-on-click {
  padding-left: 3px;
  .icon-pencil{
    color: $av-red;
    height: 12px;
    margin-left: 5px;
  }
}

textarea{
  width: 100%;
  resize: none;
}
