@import 'css/_variables.scss';

#more-info-container {
  //width: 30px;
  //height: 30px;
  position: relative;
  //display: inline-block;
  //border: 1px solid red;
  width: 30px;
  height: 30px;
  display: inline-block;
}

#more-info {
  //border: 1px solid blue;
  position: absolute;
  top: 3px;
  right: 0;
  //left: 0;
  width: 100%;
  height: 100%;
}

.more-info-dropdown {
  position: relative;
  margin-top: 5px;
  margin-left: -110px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 10px 10px 20px 10px rgba(51, 51, 51, 0.1);
  //padding: 0 0 0 20px;
  // position: absolute;
  // right: 30px;
  // top: 100px;
  //width: 100vw;
  // top: 80px;
  // //left: -150px;
  // right: 0px;
  // height: 255px;
  width: 150px;
  z-index: 2000;
  .profile {
    padding: 5px;
    // border-bottom: 1px solid #b7c7d0;
    background: $av-blue;
    color: white;

    a {
      color: white;
      text-decoration: none;

      .username {
        padding-left: 0px;
        font-size: 14px;
      }
    }
  }
  .link {
    //border-top: 1px solid #b7c7d0;
    cursor: pointer;
    padding: 5px 10px;
    color: black;
    &:hover {
      color: #1286c8;
      //background: #b7c7d0;
    }
    &.logout {
      border-top: 1px solid $av-light-blue;
      padding-top: 10px;
      color: $av-red;
    }
  }
}

.more-info-icon {
  display: inline-block;
  //color: #b7c6cf;
  .icon {
    height: 25px;
    width: 25px;
  }
}
