.radioGroup{

    margin-top: 5px;
    font-weight: bold;
    .radio-option{
        display: flex;
        align-items: center;
        margin-bottom: 5px !important;

        input{
            margin: 0 !important;
        }
        label{
            margin: 0 !important;
            font-weight: normal;
            padding-left: 10px;
        }
    }
}
