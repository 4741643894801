@import 'css/_variables.scss';

.invite-students-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .school-dropdown {
      .a-drop-down {
        margin-top: -20px;
        margin-bottom: 0;
        height: 60px;
        width: 30rem;
      }
    }

    .school-form {
      border: 1px solid $av-gray;
      border-radius: 16px;
      padding: 1rem;
    }

    .subscription-container {
      text-align: center;

      .image-preview {
        height: 30rem;
      }

      .error-message {
        color: $av-red,
      }
    }

    .submit-container {
      text-align: end;
    }
  }
}
