@import "css/_variables.scss";

.divider{
    margin: 0px;
}

.dark{

}

.medium{
    border-top: 1px solid $av-gray;
    opacity: 0.5;
}

.light{
    border-top: 1px solid $av-light-blue;
}