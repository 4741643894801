@import 'css/_variables.scss';

.collection-select-groups-modal {
  .modal-header {
    .modal-title {
      margin-top: 6px;
    }
  }

  .modal-body {
    .btn-group {
      .btn {
        font-size: 12px;
        height: 30px;
      }

      &.selected {
        .btn {
          background-color: $av-blue;
          border-width: 1px;
          color: $av-white;
        }
      }
    }

    .dont-see-group {
      text-decoration: underline;
    }

    .groups-container {
      border: 1px solid #f1f1f1;
      height: 200px;
      overflow-y: auto;

      .group {
        cursor: pointer;
        padding: 5px 20px;

        &:not(:last-child) {
          border-bottom: 1px solid #f1f1f1;
        }

        &.selected {
          background-color: #f1f1f1;
        }
      }
    }
  }
}
