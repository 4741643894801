.portfolio-card {
  margin-top: 20px;
  .header {
    background-color: #1286c8;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    font-size: 11.6px !important;
    font-weight: bold;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    width: 100%;
    height: 35px;
    line-height: 35px;
  }

  .portfolio-card-container {
    margin-bottom: 10px;

    .profile-pic {
      float: left;
    }
  }

  .user-profile {
    width: 100%;
    padding: 0 15px;
    section {
      border-bottom: 1px solid #b7c6cf;
      margin-bottom: 15px;
      padding: 0 20px 15px 0;
      .description {
        margin: 10px 0;
      }
      .stats{
        .collection-content {
          margin-top: 9px;
          margin-right: 10px;
          color: #b7c7d0;
          border: solid 1px #b7c7d0;
          padding: 5px 7px;
          border-radius: 10px;
          width:124px;
          text-align: center;
          font-size: 12px;
          display: inline-block;
          p {
            line-height: 25px;
            margin: 0;
          }
        }
        .av {
          width: 134px;
          display: inline-block;
          &.av-rating {
            color: #b7c7d0;
          }
        }
      }
    }
    section:last-child {
      border-bottom: none;
    }
    .profile-pic {
      display: inline-block;
      float: left;
    }

    .user-div {
      padding: 20px;
      display: inline-block;
      margin-left: 20px;

      .user-name {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }

      .createdBy {
        font-family: 'Open Sans';
        font-size: 12px;
      }

      .collection-count {
        font-family: OpenSans;
        font-size: 16px;
        font-weight: 600;
        color: #979797;
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ca2222;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: #2ab934;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(55px);
    -ms-transform: translateX(55px);
    transform: translateX(55px);
  }
  /*------ ADDED CSS ---------*/
  .on {
    display: none;
  }

  .off,
  .on {
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
  }

  input:checked+ .slider .on {
    display: block;
  }

  input:checked + .slider .off {
    display: none;
  }
  /*--------- END --------*/
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
