@import 'css/_variables.scss';

.sub-points {
  .add-point-inline {
    .remove-add-point-inline {
      background-color: $av-gray;
      border-radius: 100%;
      color: $av-white;
      margin-left: 4px;
      z-index: 1;
    }
  }
}
