@import 'css/_variables.scss';

.four-pack-tabs {
  background-color: $av-white;
  border-radius: 4px;
  padding: 16px;
  position: relative;

  .rectangular-button.big-button {
    border: none;
    height: 48px;
    background-color: $av-blue;
    color: white;

    .content {
      height: 100%;
      justify-content: center;
    }

    &:hover {
      background: #0a79b9;
    }

    &:active {
      background: #036aa4;
    }

    &.disabled {
      background: $gray-2;
      border: none;
      color: $gray-3;
    }
  }

  .btn-close {
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 18px;
  }

  .point-card {
    &.evidence {
      border-radius: 4px;
    }
  }

  .btn-show-all {
    color: $av-blue;
    font-weight: 600;
    text-decoration: none;
  }

  .btn-show-hide-toggle {
    color: $av-blue;
    font-weight: 400;
    text-decoration: underline;
  }
}

.evidence-connector {
  height: 20px;
  width: 1px;
  background-color: $av-blue;
  margin-left: 30px;
}

.evidence-tab {
  background-color: white;
  border-radius: 4px;
  padding: 19px 16px;

  .sub-points-count {
    background-color: $av-light-blue;
    border-radius: 4px;
    color: $av-blue;
    font-weight: bold;
    padding-right: 8px;
    padding-left: 8px;
  }

  .icon-arrow-down-round {
    fill: none;

    circle {
      fill: $av-light-blue;
    }

    path {
      stroke: $av-blue;
    }
  }

  .icon-arrow-right-round {
    fill: none;

    circle {
      fill: $av-blue;
    }

    path {
      stroke: $av-white;
    }
  }

  .point-card.top-evidence {
    // border-width: 0;
    padding-top: 1px;
    border-radius: 4px;
  }

  .create-point-light-weight {
    // margin-left: -16px;
    // margin-right: -16px;
    // margin-top: -16px;

    .create-point-entry-form {
      border-radius: 4px;

      .hint {
        display: none;
      }

      .btn-submit {
        bottom: 2px;
      }

      &.lightweight-show {
        .btn-submit {
          bottom: -65px;
        }

        .point-and-quote {
          margin-bottom: 25px;
        }
      }
    }

    .import-point-link {
      top: 16px;
      right: 40px;
      left: auto;
    }

    .import-import {
      background-color: $av-white;
      border-radius: 4px;
    }
  }
}

@media (min-width: 400px) {
  .evidence-tab {
    .create-point-light-weight {
      .create-point-entry-form {
        &.lightweight-show {
          .btn-submit {
            bottom: -32px;
          }

          .point-and-quote {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
