@import 'css/_variables.scss';

.progress-bar-next-steps-modal {
  .modal-body {
    padding: 48px;

    .btn-close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .title {
    color: $av-blue;
    font-size: 22px;
    font-weight: 400;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    margin-top: 22px;
  }

  .option {
    margin-top: 25px;
  }
}
