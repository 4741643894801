@import 'css/_variables.scss';

.landing-page {
  .center-container {
    text-align: center;
  }

  .section {
    margin: 20px auto 20px auto;

    @media (min-width: 400px) {
      width: 50%;
    }

    width: 80%;
  }

  .header {
    font-size: 28px;
    font-weight: 400;
    max-width: 600px;
    margin: auto;
  }

  .sub-text {
    font-size: 16px;
    text-align: left;

    .learning-list {
      margin-top: 10px;
    }

    .step {
      margin-top: 20px;

      .title {
        font-style: italic;
      }

      .description {}
    }
  }

  .header-button {
    height: 49px;
    border-radius: 4px;
    margin-top: 3rem;
    // padding: 0 3rem;
    width: 20rem;
    font-weight: 600;

    .content {
      height: 100%;
    }
  }

  .hero-button {
    .content {
      height: 100%;
    }
  }

  .sign-up-link {
    text-decoration: none !important;
  }

  .sign-up-button {
    background: linear-gradient(288.08deg, #1286c8, #2bb5e0 99.25%) !important;

    &:hover {
      background: linear-gradient(288.08deg, #0C78B6, #1BA8D4 99.25%) !important;
    }

    &:active {
      background: linear-gradient(288.08deg, #006299, #058EBA 99.25%) !important;
    }
  }

  .request-demo-button {
    background-color: $av-light-blue;
    color: $av-blue;
    border-color: $av-light-blue;

    &:hover {
      background-color: $av-dark-blue;
      border-color: $av-dark-blue;
    }

    &:active {
      background-color: $av-medium-blue;
      border-color: $av-medium-blue;
    }
  }
}

.submit-request-btn {
  padding: 0.75rem 3rem;
  width: 20rem;
  margin: auto;

  &.disabled {
    background-color: $gray-3 !important;
  }
}

.request-demo-role-selector {
  border-color: $av-grayish-cyan;
  background-color: white;
  outline: none !important;
}