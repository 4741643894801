@import 'css/_variables.scss';

// .source-info-container {
//   //background-color: #e6e6ed;
//   .editable-source-info {
//     // padding: 5px;

//     .hide-source-details {
//       display: flex;
//       justify-content: flex-end;
//       .icon-close {
//         color: $av-red;
//         height: 10px;
//       }
//     }
//   }
//   .top-row {
//     .close-button {
//       cursor: pointer;
//       float: right;
//     }
//     height: 30px;
//   }

//   .url-form-group {
//     position: relative;
//     .fa-spin {
//       position: absolute;
//       right: 9px;
//       top: 9px;
//       z-index: 100;
//     }
//     .icon-with-tool-tip {
//       position: absolute;
//       right: 9px;
//       top: 20px;
//       z-index: 100;
//     }
//     &.url-input {
//       //border: 1px solid red;
//       display: flex;
//       justify-content: flex-start;
//       width: 100%;
//       .floating-input {
//         //border: 5px solid green;
//         width: 90%;
//       }
//       .copy-url-button,
//       .lookup-url-button {
//         width: 70px;
//         color: $av-white;
//         height: 40px;
//         background-color: $av-blue;
//         //float:right
//         display: inline-block;
//       }
//     }
//   }
// }

// .source {
//   position: relative;
//   overflow: hidden;

//   .source-quote {
//     // border-left: 2px solid $av-blue;
//     // word-break: break-all;
//   }

//   .citation {
//     vertical-align: middle;

//     .source-author-name {
//       width: 200px;
//       white-space: nowrap;
//       text-overflow: ellipsis;
//       overflow: hidden;
//     }
//     .icon-close {
//       color: $av-red;
//       height: 12px;
//     }
//   }
//   .source-footnote {
//     padding: 0px 15px;
//   }
//   .source-image {
//     display: flex;
//     justify-content: center;
//     max-height: 200px;
//     margin-top: 0px;
//     .image-container {
//       position: relative;
//       //border: 1px solid red;
//       img {
//         max-height: 200px;
//       }
//       svg.icon-close {
//         position: absolute;
//         right: 4px;
//         top: 4px;
//         color: $av-red;
//         cursor: pointer;
//       }
//     }
//   }
//   .source-logo {
//     margin-left: 5px;

//     img {
//       width: 16px;
//       margin-top: -3px;
//     }
//   }
//   .details-button-wrapper {
//   }
// }

.source {
  border: 1px solid #dbe1e4;
  border-radius: 8px;

  .quote {
    border-bottom: 1px solid #dbe1e4;
  }

  .citation {
    font-size: 12px;
    border-radius: 8px;

    .toggle-expand {
      cursor: pointer;
    }

    .citation-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.has-text {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
