@import 'css/_variables.scss';

.collection-header-controls {
  .icon-pencil {
    color: $av-blue;
  }

  .visible-links {
    margin-left: -120px;
  }

  .dropdown-menu {
    li {
      a {
        display: flex;
        align-items: center;
        padding: 7px 20px;
        color: currentColor;

        &:hover {
          color: currentColor;
        }

        & > .icon {
          color: $av-grayish-cyan;
          width: 16px;
        }
      }

      &.av-red a {
        .icon {
          color: $av-red;
        }
      }
    }

    .separator {
      height: 1px;
      background-color: $av-grayish-cyan;
    }
  }

  .notification-settings-menu-item {
    .notification-settings {
      background-color: white;
      box-shadow: 0px 8px 22px rgba(51, 51, 51, 0.1);
      border: 1px solid $av-grayish-cyan;
      border-radius: 5px;
      display: none;
      top: 0;

      &.right {
        right: calc(100% + 1px);
      }

      &.left {
        left: calc(-100% - 1px);
      }

      .item {
        &:not(:last-child) {
          border-bottom: 1px solid $av-light-gray;
        }

        .checkbox {
          margin: 0;

          label {
            padding-left: 0;
          }
        }

        &.hover {
          &:hover {
            background-color: $av-light-gray;
          }
        }
      }
    }

    &:hover {
      .notification-settings {
        display: block;
      }
    }
  }
}

@media (max-width: 768px) {
  .collection-header-controls {
    &.card-type-page {
      .save-button-container,
      .lock-icon-container {
        display: none;
      }
    }
  }
}
