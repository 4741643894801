.navbar-profile-container {
  display: flex;
  align-items: center;
}
#profile-notes {
  display: inline-block;
}

.username {
  display: inline-block;
  margin-left: 5px;
  white-space: nowrap;
}

.level {
  display: inline-block;
  margin-left: 5px;
}
