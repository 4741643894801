@import 'css/_variables.scss';

.averpoint-navbar {
  height: 70px;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #f7f7f7;
  margin-left: 0px;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .mobile-header-links {
    display: none;
  }

  .navbar-brand {
    height: 70px;
    cursor: pointer;

    @media (min-width: 992px) {
      margin-right: 20px;
    }

    padding: 20px 25px;

    .icon-logo {
      width: 121px;
    }
  }

  .search-items-container {
    .search-items {
      display: inline-block;
      width: 300px;
      margin-right: 100px;
      border: none !important;
      border-bottom: 1px solid $av-gray !important;
    }

    .icon-close {
      display: inline-block;
      margin-left: -110px;
      margin-top: 0px;
      color: red;
      height: 10px;
      cursor: pointer;
    }
  }

  .header-links {
    padding: 0px 10px;
    border: none;
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    //border: 1px solid red;
    margin-left: auto;

    @media (max-width: 768px) {
      display: none;
    }

    >a.message {
      margin-right: 20px;
    }

    .authenticated-links {
      //float: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .unauthenticated-links {
      //float: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .nav-link {
      padding: 24px 20px;
      //height: 78px;
      //margin: 0px 5px;
      min-width: 90px;
      color: $av-black;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      // border-bottom: solid 2px transparent;
      transition: all 0.2s ease;

      &.groups {
        margin-right: 20px;
      }

      &.nav-profile {
        padding: 19px 20px;
        // margin-right: 30px;
      }

      .profile-image {
        border: solid 2px $av-gray;
      }

      &.active {
        // border-bottom: solid 2px $av-blue;
        color: $av-blue;
        text-decoration: none;

        .profile-image {
          border: solid 2px $av-blue;
          //color: $nickel;
        }

        [tooltip] {
          font-weight: 600;
        }
      }

      &:hover {
        // border-bottom: solid 2px $av-blue;
        color: $av-blue;
        text-decoration: none;

        .profile-image {
          border: solid 2px $av-blue;
          //color: $nickel;
        }
      }

      &.featured {
        width: 150px;
      }

      &.sign-up {
        color: $av-orange;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .more-menu {
      .dropdown-toggle {
        padding: 10px 20px;
        display: flex;
        align-items: center;
      }
    }

    .nav-icon {
      //border: 1px solid blue;
      //padding: 0 10px;
      //height: 78px;
      //min-width: 50px;
      padding: 24px 10px;
      //border: 1px solid red;
      color: $av-gray;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &.active {
        color: $av-blue;
        text-decoration: none;
      }

      &.create-point {
        min-width: 0px;

        .create-point-link {
          .icon {
            width: 15px;
            margin-left: 2px;
          }
        }

        .tabs-container {
          color: $av-black;
          top: 0;
          right: 16px;
          z-index: 100000;
          width: 500px;

          .nav-tabs {
            border: none;
            display: flex;
            justify-content: center;

            li {
              visibility: hidden;
              height: 70px;

              a {
                color: $gray-3;
                border: none;
                font-size: 22px;
                padding-top: 20px;
                padding-bottom: 17px;
              }

              &.active {
                border-bottom: 2px solid $av-blue;

                a {
                  color: $av-blue;
                }
              }
            }
          }

          .tab-content {
            box-shadow: 0px 8px 22px rgba(51, 51, 51, 0.1);
            border-top: 1px solid #f7f7f7;

            .privacy-save-container {
              position: sticky;
              bottom: 0;
            }
          }

          .close-add-point {
            position: absolute;
            right: 30px;
            top: 30px;
            color: $av-red;
          }
        }
      }

      &.notifications {
        margin-right: -10px;
      }

      .more-info-dropdown {
        color: $av-gray;
      }

      .user-level {
        padding: 0px 0px;
        color: $av-gray;

        &:hover {
          color: $av-gray;
        }
      }
    }
  }

  .extension-bar {
    background: #ffc;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 35px;
    align-items: center;
    padding: 2px 10px;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.2);
    z-index: 1500;

    .message {
      flex: 1;
      color: black;
    }

    .close {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .dropdown-toggle {
    font-size: 14px;
    font-weight: bold;
    background: transparent !important;
    box-shadow: none !important;
  }

  .dropdown-menu {
    z-index: 1001;
    font-size: 14px;

    li {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

@media (min-device-width: 768px) and (max-device-width: 1205px) {}

@media screen and (max-width: 767px) {
  .Select--single {
    width: 100% !important;
    z-index: 9;
  }

  .averpoint-navbar {
    height: 75px;
    margin-left: 0px;

    .search-items-container {
      position: absolute;
      top: 15px;
      right: 0;
      width: 250px;
      background-color: $av-white;

      .search-items {
        width: 100%;
        margin: 0 !important;
      }
    }

    .mobile-header-links {
      display: flex;
      align-items: center;
      //width: 100px;
      height: 17px;
      //font-family: 'Open Sans';
      //text-decoration: none;
      font-size: 14px;
      //line-height: 1.21;
      //text-align: right;
      color: $av-gray;
      float: right;
      padding: 30px 0px;
      margin-right: 0px;

      .search {
        margin-top: 5px;
        margin-right: 10px;

        .icon-search {
          width: 20px;
          height: 20px;
        }
      }

      .navbar-profile-container {
        margin-right: 20px;
        display: none;

        .username {
          display: none;
        }
      }

      .nav-icon:not(.notifications) {
        display: none;
      }

      .notifications {
        margin-right: 48px;
      }

      .sign-up {
        font-weight: bold;
        color: $av-orange;
      }
    }
  }
}