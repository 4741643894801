@import 'css/_variables.scss';

.edit-source-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
      }
    }

    .source-format-selector {
      margin: 0 !important;
      height: 30px;
      display: inline-flex;
      flex: 1;
      width: 20rem;

      > div {
        flex: 1;
      }
    }
  }
}
