@import '../../../css/variables';

.dragable-card {
  // transform: translate3d(0, 0, 0);

  .drag-icon {
    left: 0;
    top: 10px;
    z-index: 1;
    cursor: pointer;
    width: 12px;
    overflow: hidden;
  }

  &.hover {
    .bullseye {
      &.can-drop {
        display: block;
        opacity: 1;
        // transition: opacity 1s 2s;
      }
    }

    // &.direction-up {
    //   border-top: 2px solid $av-blue;
    // }

    // &.direction-down {
    //   border-bottom: 2px solid $av-blue;
    // }

    // &.direction-left {
    //   border-left: 2px solid $av-blue;
    // }

    // &.direction-right {
    //   border-right: 2px solid $av-blue;
    // }
  }
}

.point-card-main {
  border-radius: 8px;
  position: relative;

  &.shadow {
    box-shadow: 0px 8px 22px rgba(51, 51, 51, 0.1) !important;
  }

  .point-save-button,
  .point-review-button {
    //border: 1px solid red;
    margin-right: 10px;
    padding: 3px 5px 3px 5px;
    border-radius: 0px;
    // border: 1px solid $av-gray;
    display: flex;
    align-items: center;
    height: 30px;

    .point-save-button-content,
    .point-review-button-content {
      display: flex;
      align-items: center;
      font-weight: 400;
    }
  }

  .visible-links {
    //border: 1px solid red;
    //margin-left: -150px;
  }

  .icon-pencil {
    color: $av-red;
    height: 12px;
  }

  .actions {
    // opacity: 0.6;
    width: 100%;
    // padding-bottom: 20px;
  }

  .editMessage {
    float: right;
  }

  .not-editing-footer {
    height: 20px;
  }

  // .point-edit-controls {
  //   display: flex;
  //   justify-content: flex-end;
  //   //visibility: hidden;
  //   margin-right: 20px;
  //   margin-top: 20px;
  // }

  .point-edit-messages {
    display: flex;
    justify-content: center;

    .error {
      color: $av-red;
    }
  }

  .more-container {
    opacity: 1; //Change to hidden if more shown only on hover
    visibility: visible; //Change to hidden if more on hover
    position: relative;
    float: right;
    display: flex;
    justify-content: flex-end;
    margin-top: 3px;
    margin-right: 3px;

    .icon-more {
      color: $av-grayish-cyan;
      width: 20px;
      height: 20px;
    }

    width: 30px;
    height: 30px;

    .more-component {
      position: absolute;
      width: 100%;
      height: 100%;

      .action-link {
        //display: inline-block;
      }
    }
  }

  .hover-box {
    // opacity: 0;
    // visibility: hidden;
    transition-delay: opacity 2s;
    transition: opacity 3s;
    z-index: 100000;
    position: absolute;
    // display: none;
    right: 0px;
    top: 0px;
    width: 135px;
    background: $av-white;
    font-weight: 400;
    margin: 0px 5px 0px 5px;
    box-shadow: 10px 10px 20px 10px rgba(51, 51, 51, 0.1);

    .icon-close {
      //float: right;
      position: absolute;
      top: 1px;
      right: 5px;
      color: $av-red;
      padding-top: 3px;
      padding-right: 3px;
    }

    .fact-status-image {
      width: 10px;
    }

    .arrow-down {
      width: 0;
      height: 0px;
      margin-top: -7px;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid $av-white;
      //box-shadow: 10px 10px 20px 10px rgba(51, 51, 51, 0.1);
    }

    .point-tags {
      margin-top: 3px;
      margin-bottom: 3px;
      margin-left: 5px;
    }
  }

  &.disableClickThru {
    &:hover {
      box-shadow: none;
    }
  }

  &.preview {
    &.highlight-on-hover {
      &:hover {
        border-color: rgba(89, 77, 77, 0.44);
        cursor: pointer;
      }
    }
  }

  &.default {
    border-width: 1px;

    &:hover {
      cursor: pointer;
    }
  }

  &.page {
    .more-icon {
      opacity: 1;
      visibility: visible;
    }
  }

  &.embed {
    border-width: 1.5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    max-width: 600px;
    height: 100%;
    width: 90%;

    .more-icon {
      opacity: 1;
      visibility: visible;
    }

    &:hover {
      cursor: pointer;
    }

    &::-webkit-scrollbar {
      -webkit-appearance: none !important;
      width: 0 !important;
    }
  }

  &.point-card-main-highlighted {
    border-color: $av-red;
  }

  &.editing {
    border-color: $av-red;
  }

  .card-overlay {
    color: #0470ff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
  }

  &.focused {
    border-color: $av-green;
  }

  &.selected {
    > .point-card {
      background-color: #f5fcff;
      border: 2px solid #a5cbe9;
    }

    > .point-stack-container {
      .point-stack-line-1,
      .point-stack-line-2 {
        border-color: #a5cbe9;
      }
    }

    .top-evidence {
      margin-top: 0;
    }
  }

  &.questioned {
    .qtp-card.questioned-point-qtp {
      margin-top: -2px;
    }
  }

  &.reviewed {
    .rtp-card.reviewed-point-rtp {
      margin-top: -2px;
    }
  }
}

.bullseye {
  border: 1px dashed $av-blue;
  border-radius: 8px;
  background: rgba(18, 134, 200, 0.1);
  top: calc(50% - 23px);
  left: calc(50% - 100px);
  width: 200px;
  display: none;
  opacity: 0;
  transition: opacity 0.5s 2s;

  &.hover {
    background: rgba(18, 134, 200, 0.2);
    border-style: solid;
  }

  &.can-drop {
    display: block;
  }
}
