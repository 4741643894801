.dragable-card {
  margin-left: -13px;
  padding-left: 13px;

  &.is-dragging {
    & > .drag-preview {
      .collection-card,
      .point-card-main {
        transform: rotate(-5deg);
        transform-origin: 0 0;
      }
    }

    overflow: hidden;
  }

  &.fade-in {
    animation: fadeIn ease 1s;
  }

  .drag {
    visibility: hidden;
    opacity: 0;
    transition: visibility 300ms linear 0ms, opacity 300ms linear 0ms;
  }

  &:hover {
    .drag {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 300ms, opacity 300ms linear 300ms;
    }

    .point-header {
      .icon-timecodes {
        visibility: hidden;
        transition: visibility 0s linear 300ms, opacity 300ms linear 300ms;
      }
    }
  }

  .drag-preview {
    z-index: -1;
    top: 0;
    left: 23px;
    bottom: 0;
    right: 0;

    .collection-card,
    .point-card-main {
      overflow: hidden;
      max-height: 150px;
      // transform: rotate(-5deg);
    }
  }

  .drag-overlay {
    border-radius: 8px;
    bottom: 0;
    left: 23px;
    top: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
