@import 'css/_variables.scss';

.floating-input {
  height: 50px;
  position: relative;
  margin: 0 0 20px;

  input {
    border: none;
    border-bottom: 1px solid $av-grayish-cyan;
    bottom: 0;
    color: #333;
    display: block;
    font-size: 14px;
    line-height: 1.42857;
    padding: 10px 0;
    position: absolute;
    width: 100%;
    background: transparent;

    &:focus {
      outline: none;
    }

    &:disabled {
      color: $av-gray;
    }
  }

  label {
    bottom: 10px;
    color: $av-gray; // #333;
    font-size: 14px;
    font-weight: normal;
    left: 0px;
    line-height: 1.42857;
    padding: 0;
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
  }

  .required-field {
    // color: $av-red;
    margin-left: 0.5rem;
  }

  input[type='date'] ~ label,
  input[type='datetime-local'] ~ label {
    top: -5px;
  }

  input:focus ~ label,
  input:disabled ~ label,
  input:valid ~ label {
    font-size: 10px;
    font-weight: 600;
    left: 0;
    letter-spacing: 0.5px;
    line-height: 1;
    top: -5px;
  }

  input[type='date']:focus ~ label,
  input[type='date']:disabled ~ label,
  input[type='date']:valid ~ label,
  input[type='datetime-local']:focus ~ label,
  input[type='datetime-local']:disabled ~ label,
  input[type='datetime-local']:valid ~ label {
    top: -5px;
  }

  input[type='datetime-local']::-moz-selection,
  input[type='datetime-local']::selection {
    /* Code for Firefox */
    color: red;
    background: yellow;
  }

  input[type='datetime-local']::-webkit-datetime-edit-day-field:focus,
  input[type='datetime-local']::-webkit-datetime-edit-month-field:focus,
  input[type='datetime-local']::-webkit-datetime-edit-year-field:focus {
    background-color: $av-blue;
    color: white;
    outline: none;
  }

  input:disabled ~ label {
    color: #ccc;
    opacity: 1 !important;
  }

  input[type='datetime-local']:disabled {
    background-color: #f2f2f2;
    color: #999999;
  }

  input:valid ~ label {
    color: #ccc;
  }

  input:focus ~ label,
  input:focus:valid ~ label {
    color: $av-blue;
  }

  .bar {
    bottom: 0;
    position: absolute;
    display: block;
    width: 100%;
  }

  .bar:after,
  .bar:before {
    content: '';
    height: 1px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: $av-blue;
    transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }

  .bar:after {
    right: 50%;
  }

  span.error {
    float: right;
    position: absolute;
    right: 0;
    //top: 23px;
    font-size: 10px;
    color: $av-red;
    //color: blue;
    font-family: 'Open Sans';
  }

  span.hint {
    float: right;
    position: absolute;
    right: 0;
    //top: 23px;
    font-size: 10px;
    color: $av-blue;
    //color: blue;
    font-family: 'Open Sans';
    display: none;
  }

  /* active state */
  input:focus ~ .bar:after,
  input:focus ~ .bar:before {
    width: 50%;
  }

  input:focus ~ .hint {
    display: block;
  }

  &.errorInput {
    input {
      border-bottom: 1px solid $av-red;
    }
  }

  .close-icon {
    position: absolute;
    right: 8px;
    top: 30px;
    cursor: pointer;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  @media (max-width: 767px) {
    margin: 0 0 10px;

    input {
      font-size: 12px;
    }

    label {
      font-size: 12px;
    }

    input[type='color'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='email'],
    input[type='month'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    input[type='time'],
    input[type='url'],
    input[type='week'],
    select:focus,
    textarea {
      font-size: 12px;
    }
  }
}
