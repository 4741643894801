$av-grayish-cyan: #b7c6cf;
$av-light-blue: #e8f1f8;
$av-dark-blue: #d5e7f4;
$av-blue: #1286c8;
$av-dark-blue-hover: #0a79b9;
$av-dark-blue-active: #036aa4;
$av-light-blue-hover: #d0e7f4;
$av-light-blue-active: #b8dbef;
$av-light-gray: #f8f9fa;
$av-gray: #979797;
$av-red: #f23c51 !default;
$av-dark-red: #af1123 !default;
$av-white: #ffffff;
$av-camel: #c28e4c !default;
$av-green: #7fb800 !default;
$av-gold: #ffb400 !default;
$av-orange: #f6511d !default;
$av-black: #333333;
$av-accent-aqua: #26c6da;
$av-dark-gray: #8ea5b3;
$av-dark-gold: #dc8b01;
$av-accent-cyan: #26a69a;
$av-accent-cyan-light: #e0f2f1;
$av-medium-blue: #c4ddf1;
$av-new-black: #172127;
$av-accent-pink: #d81b60;
$av-accent-purple: #7e57c2;
$av-accent-hyperlink: #0c4fd4;
$av-lapis: #0e6495;

$error: #ff6060 !default;

/* Shades of Gray */
$nickel: #4b4b55 !default; // Profile Image and Header
$chrome: #d2d2d9 !default; // Used in ReviewForm and forms
$chalk: #e6e6ed !default; // Used in editable component
$ivory: #f0f0f5 !default; // Parent background header

$gray-1: #f8f9fa;
$gray-2: #dbe1e4;
$gray-3: #a4b4bd;
$gray-4: #65737b;
$gray-6: #40494f;
$gray-7: #f1f3f4;

$black: #000000;
$gray-dark: #2e3438;
$indigo: #093e5d;
