@import 'css/_variables.scss';

.image-overlay {
  width: 100%;
  background-color: $av-black;
  opacity: 0.4;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  outline: none;
  cursor: pointer !important;
}
.pencil-wrapper {
  margin-top: -15px;
  position: absolute;
  top: 50%;
  left: 45%;
  outline: none;
}
.profile-pencil-icon {
  color: $av-white;
}
