.searchableDropDown{
  width: 300px;
  .itemList {
    height: auto;
    width: 300px;
    max-height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  overflow-x: hidden;
}
