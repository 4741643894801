@import 'css/_variables.scss';

.bottom-sheet {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $av-light-blue;
  padding: 2rem;
  z-index: 2147483647;
}
