@import 'css/_variables.scss';
.point-modal {
  height: calc(100% - 20px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 6px;

  .modal-content {
    width: 600px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;

    .modal-body {
      padding-top: 0px;
      padding-bottom: 0;

      .point-parent-header {
        background-color: transparent;
        border-bottom: 1px solid rgba($color: $gray-3, $alpha: 0.5);
        padding: 14px 16px;
      }
    }
  }

  .icon-close {
    color: $av-grayish-cyan;
    cursor: pointer;
    right: 6px;
    top: 3px;
    z-index: 1;
  }

  .container {
    width: 100%;
  }

  .point-card.page {
    border: none;
    padding-top: 12px;

    & + .point-stack-container {
      display: none;
    }
  }

  .point-card-main.page {
    padding: 12px 16px;
  }

  .point-card-main.sticky {
    position: sticky;
    top: 0;
    z-index: 10;
    // background-color: white;
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

    &.is-sticky {
      .point-card {
        .point-header,
        .author-container,
        .point-tags {
          display: none !important;
        }
      }

      .point-image-container {
        position: absolute;
        top: 20px;
        border-radius: 8px;
      }
    }
  }

  .collection-points-navigation {
    display: none !important;
  }
}

.collection-points-navigation {
  .count {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 100px;
    color: white;
    bottom: 7px;
    left: 50%;
    position: absolute;
    font-size: 14px;
    transform: translateX(-50%);
    padding: 15px 28px;
    font-weight: bold;
  }

  .next,
  .previous {
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    bottom: 7px;
    padding: 12px;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .previous {
    left: 10px;
  }

  .next {
    right: 10px;
  }
}

@media (min-width: 768px) {
  .modal-dialog.point-modal {
    width: 100%;
    margin-top: 70px;
    height: calc(100% - 100px);
  }

  .collection-points-navigation {
    .count {
      top: 7px;
      bottom: unset !important;
      font-weight: bold;
      padding: 16px 40px;
      font-size: 16px;
    }

    .next,
    .previous {
      width: 96px;
      height: 96px;
      top: 50%;
      transform: translateY(-50%);
      padding: 24px;

      .icon {
        width: 48px;
        height: 48px;
      }
    }
  }
}
