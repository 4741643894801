@import 'css/_variables.scss';

.point-header {
  // min-height: 30px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .new-badge {
    background-color: $av-white;
    color: $av-green;
    border: 1px solid $av-green;
    font-weight: 500;
    position: absolute;
    top: 8px;
  }
  .round-btn {
    border-radius: 25px !important;
    border: 1px solid $av-blue;
    color: $av-blue;
    font-weight: 600;
    padding: 7px;
    min-width: 70px;
  }

  .btn-container {
    button {
      border-radius: 25px !important;
      // padding-top: 7px !important;
      // padding-bottom: 7px !important;
      font-size: 12px;
      min-width: 70px;
    }
  }

  .author-container {
    border: none !important;
  }

  .timestamp {
    input {
      border: none;
      outline: none;
      width: 100px;
      backgroundcolor: white;
    }
  }

  .expand-transcript {
    text-decoration: underline !important;
  }

  .more-authors-count {
    border-radius: 24px;
    height: 24px;
    margin-left: 10px;
    padding-left: 4px;
    padding-right: 4px;
  }
}
