.av-grayish-cyan {
  color: $av-grayish-cyan;
}

.av-light-blue {
  color: $av-light-blue;
}

.av-gray {
  color: $av-gray;
}

.av-camel {
  color: $av-camel;
}

.av-blue {
  color: $av-blue;
}

.av-green {
  color: $av-green;
}

.av-red {
  color: $av-red;
}

.av-gold {
  color: $av-gold;
}

.av-orange {
  color: $av-orange;
}

.av-white {
  color: $av-white;
}

.av-black {
  color: $av-black;
}

.av-accent-aqua {
  color: $av-accent-aqua;
}

.av-dark-gold {
  color: $av-dark-gold;
}

.av-accent-cyan {
  color: $av-accent-cyan;
}

.gray-2 {
  color: $gray-2;
}

.gray-3 {
  color: $gray-3;
}

.gray-4 {
  color: $gray-4;
}

.av-medium-blue {
  color: $av-medium-blue;
}

.bg-av-blue {
  background: $av-blue;
}

.bg-av-camel {
  background: $av-camel;
}

.bg-av-green {
  background: $av-green;
}

.bg-av-orange {
  background: $av-orange;
}

.bg-av-white {
  background: #ffffff;
}

.bg-av-grayish-cyan {
  background: $av-grayish-cyan;
}

.bg-av-light-gray {
  background: $av-light-gray;
}

.bg-av-light-blue {
  background: $av-light-blue;
}

.bg-av-red {
  background: $av-red;
}

.bg-av-black {
  background: $av-black;
}

.bg-gray-1 {
  background: $gray-1;
}

.bg-gray-2 {
  background: $gray-2;
}

.bg-av-accent-cyan-light {
  background-color: $av-accent-cyan-light;
}

.bg-av-medium-blue {
  background-color: $av-medium-blue;
}

.border-av-blue {
  border: 1px solid $av-blue;
}

.border-av-black {
  border: 1px solid $av-black;
}

.border-av-light-gray {
  border: 1px solid $av-light-gray;
}

.border-av-green {
  border: 1px solid $av-green;
}

.border-av-red {
  border: 1px solid $av-red;
}

.text-underline {
  text-decoration: underline;
}
