@import 'css/_variables.scss';

svg.icon {
  display: inline-block;
  fill: currentColor;
  width: 16px;

  &.icon-add {
    height: 12px;
    width: 12px;
  }

  &.icon-close {
    height: 11px;
    width: 11px;
  }

  &.icon-drop-down {
    height: 24px;
    width: 24px;
  }

  &.icon-globe {
    //color: $av-green
  }

  &.icon-import {
    height: 14px;
    width: 13px;
  }

  &.icon-invite {
    height: 11px;
    width: 13px;
  }

  &.icon-lock {
    //color: $av-red
    //color: #ED4337
    //color: #806D47
  }

  &.icon-pencil {
    // color: $av-red;
    height: 12px;
  }

  &.icon-refresh {
    height: 12px;
    width: 12px;
  }

  // &.icon-rejected {
  //   color: $av-red;
  // }

  // &.icon-review {
  //   height: 10px;
  //   width: 23px;
  // }

  &.icon-search {
    height: 15px;
    width: 15px;
  }

  &.icon-share {
    height: 13px;
    width: 11px;
  }

  &.icon-trash {
    height: 12px;
    width: 11px;
  }

  &.icon-verified {
    color: $av-green;
  }

  &.icon-star {
    fill: none;
    stroke: currentColor;
    stroke-width: 2px;
  }

  &.icon-topics {
    margin-top: 9px;
  }

  &.icon-cardview,
  &.icon-arrow-forward-round,
  &.icon-arrow-backward-round {
    clip-rule: evenodd;
    fill: currentColor;
    stroke-width: 2px;
    fill-rule: evenodd;
  }

  &.icon-user,
  &.icon-profile,
  &.icon-analytics,
  &.icon-announcements,
  &.icon-chevron-left,
  &.icon-chevron-right,
  &.icon-chevron-down,
  &.icon-minus,
  &.icon-up,
  &.icon-down,
  &.icon-right,
  &.icon-left,
  &.icon-folder-2,
  &.icon-review,
  &.icon-rejected,
  &.icon-approved,
  &.icon-curation {
    fill: none;
    stroke: currentColor;
  }

  &.icon-review-timeline,
  &.icon-evidence-timeline,
  &.icon-qtp-timeline,
  &.icon-verified-timeline,
  &.icon-rejected-timeline,
  &.icon-pointing-arrow,
  &.icon-video-play,
  &.icon-list-text {
    fill: none;

    path {
      fill: currentColor;
    }
  }

  &.icon-question-hamburger,
  &.icon-hamburger {
    fill: none;

    path,
    rect {
      fill: currentColor;
    }
  }

  &.icon-minus {
    stroke-width: 2px;
  }

  &.icon-chevron-left,
  &.icon-chevron-right,
  &.icon-chevron-down {
    stroke-width: 2px;
  }

  &.icon-users,
  &.icon-timeline {
    fill-rule: evenodd;
    clip-rule: evenodd;
  }

  &.icon-media {
    fill: none;

    path,
    rect {
      stroke: currentColor;
    }

    path {
      fill: currentColor;
    }
  }

  &.icon-drag {
    path {
      fill: currentColor;
    }
  }

  &.icon-ellipse,
  &.icon-left,
  &.icon-right {
    fill: currentColor;
  }

  &.icon-check {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  &.icon-picture {
    fill: none;
    stroke: currentColor;
    circle {
      fill: currentColor;
    }
  }

  &.icon-collection,
  &.icon-point,
  &.icon-user-plus,
  &.icon-settings,
  &.icon-classify,
  &.icon-cta,
  &.icon-remove-from-collection,
  &.icon-delete,
  &.icon-follow,
  &.icon-capture-area,
  &.icon-save,
  &.icon-user-edited,
  &.icon-timecodes,
  &.icon-preview,
  &.icon-export,
  &.icon-group,
  &.icon-tab-switch,
  &.icon-dropdown-right,
  &.icon-tab-switch,
  &.icon-login,
  &.icon-topics,
  &.icon-add-point,
  &.icon-qtp,
  &.icon-transcript,
  &.icon-activity {
    fill: none;
    stroke: currentColor;
  }

  &.icon-adjust-claim {
    fill: none;
  }

  &.icon-dropdown-up,
  &.icon-dropdown-right,
  &.icon-save-1 {
    fill: currentColor;
  }

  &.icon-upload-picture,
  &.icon-source,
  &.icon-tags,
  &.icon-attach-pic,
  &.icon-evidence,
  &.icon-question,
  &.icon-newspaper-outline,
  &.icon-document-text-outline,
  &.icon-library-outline {
    fill: none;
    stroke: currentColor;

    .fill {
      fill: currentColor;
    }
  }

  &.icon-send,
  &.icon-retweet-arrows {
    fill: none;

    path {
      fill: currentColor;
    }
  }

  &.icon-collection-expand,
  &.icon-collection-collapse,
  &.icon-collection-empty,
  &.icon-group-empty,
  &.icon-group-expand,
  &.icon-group-collapse {
    fill: none;
  }

  &.icon-education {
    width: 24px;
    height: 27px !important;
    margin-top: -5px;
  }
  &.icon-profilestar,
  &.icon-sector,
  &.icon-medal {
    margin-top: -1px;
  }

  &.icon-request-evidence,
  &.icon-split-horizontal,
  &.icon-split-vertical,
  &.icon-topics,
  &.icon-domain {
    fill: none;
    stroke: currentColor;

    .fill {
      fill: currentColor;
    }
  }
  &.icon-topics,
  &.icon-domain {
    color: $av-white;
  }

  &.icon-article,
  &.icon-video {
    fill: none;

    rect {
      stroke: currentColor;
    }

    path {
      fill: currentColor;
      stroke-width: 0;
    }
  }

  &.icon-folder-open-outline {
    fill: none;
    --color-start: currentColor;
    --color-stop: currentColor;
  }

  &.icon-grade,
  &.icon-grade-check,
  &.icon-grade-i {
    fill: none;
  }

  &.icon-stp {
    fill: none;
  }
}
