@import '../../../../../css/variables';


.review-form {
  position: relative;
  top: 10px;
  // &:before {
  //   color: #b7c7d0;
  //   content: ''; //use to place character in border
  //   font-size: 62px;
  //   font-style: italic;
  //   left: 28px;
  //   line-height: 1;
  //   position: absolute;
  //   top: -43px;
  //   -webkit-transform: rotate(180deg);
  //   -ms-transform: rotate(180deg);
  //   transform: rotate(180deg);
  // }
  .content-hidden {
/*    opacity: 0.4;
    pointer-events: none;
*/
  }
  .head-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    padding-top: 15px;
  }

  .show-review {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .content{
    margin-top: 0px;
    display: inline-block;

    padding-left: 10px;
    .button-container {
      margin-top: 0px;
  
      .btn {
          margin-right: 20px;
          line-height: 25px;
          background-color: transparent;
          border-color: $chrome;
          color: #979797;
          outline: none;

          img {
              width: 25px;
              margin-right: 10px;
          }
      }

      .active {
        border-color: $av-blue;
      }
    }
    .challenge-text{
      margin-bottom: 20px;
    }
    .review-options{
      .option{
        .fake-checkbox{
           font-size: 14px;
           //cursor: pointer;
           &.disabled{
             //cursor: not-allowed;
           }
        }
        .fake-checkbox-disabled{
          // cursor: not-allowed;
          // border: 1px solid red;
        }
      }
    }
    ul{
      li{
        font-family: "Open Sans";
        font-size: 12px;
        cursor: pointer;
        display: inline-block;
        &.state{
          position: relative;
          margin: 0 13px;
          &:after {
            content: "";
            position: absolute;
            left: -14px;
            width: 14px;
            height: 14px;
            border: 3px solid #1286c8;
            border-radius: 50px;
            top: 4px;
          }

          &.unSelect {
            &:after {
              background-color: transparent;
              border: 1px solid #1286c8;
            }
          }
        }
        &.state-square {
          display: grid;
          padding: 5px 0;
          .fake-checkbox {
            margin-right: 5px;
          }
        }
      }
    }
    .error-message{
      color: red;
      margin-bottom: 7px;
    }
  }

  .update {
    display: inline-block;
    color: #0085CB;
    font-size: 12px;
    font-weight: bold;
    padding-right: 12px;
    padding-bottom: 5px;
  }
}