@import 'css/_variables.scss';

.manage-writing-prompt-points-modal {
  .selected-points {
    border: 1px solid $av-grayish-cyan;

    .selected-point {
      padding: 5px 10px;

      &:not(:last-child) {
        border-bottom: 1px solid $av-grayish-cyan;
      }

      &:hover {
        background-color: $av-light-gray;
      }

      .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .selected-collection {
    &:not(:last-child) {
      border-bottom: 1px solid $av-grayish-cyan;
    }

    .selected-point {
      padding-left: 30px;
    }
  }

  [tooltip-position='left']::after {
    width: 300px;
  }

  .point-start-container {
    border: 1px solid $av-grayish-cyan;
  }

  .readings {
    border: 1px solid $av-grayish-cyan;
    max-height: 320px;
    overflow-y: auto;

    .reading {
      padding: 5px 10px;

      &:not(:last-child) {
        border-bottom: 1px solid $av-grayish-cyan;
      }

      &.selected {
        background-color: $av-light-blue;
      }
    }
  }
}
