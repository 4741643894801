@import 'css/_variables.scss';

// .add-link{
//     margin-right: 10px;
// }

.point-edit-controls {
  .icon {
    border-radius: 18px;
    color: $av-grayish-cyan;
    cursor: pointer;
  }

  .icon-source {
    &.active {
      background-color: #e0f2f1;
      color: #26a69a;
    }
  }

  .icon-tags {
    &.active {
      background-color: #defbff;
      color: #26c6da;
    }
  }

  .icon-attach-pic {
    &.active {
      background-color: #e1f5ff;
      color: #0288d1;
    }
  }
}
