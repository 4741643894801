h1,
.h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 65px;
}

h2,
.h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  // line-height: 49px;
}

h3,
.h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
}

h4,
.h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

h5,
.h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  //line-height: 19px;
}

.body-large {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.body-medium {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.body-small {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.body-xsmall {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 22px;
}

.body-quote {
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-22 {
  font-size: 22px;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

@media (max-width: 768px) {
  .body-large {
    font-size: 14px;
  }

  .body-medium {
    font-size: 12px;
  }

  .body-quote {
    font-size: 12px;
  }

  .font-size-sm-22 {
    font-size: 22px !important;
  }

  .font-size-sm-18 {
    font-size: 18px !important;
  }

  .font-size-sm-14 {
    font-size: 14px !important;
  }

  .font-size-sm-12 {
    font-size: 12px !important;
  }
}