@import 'css/_variables.scss';

.share {
  max-width: 426px;

  .modal-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;

    .modal-body {
      padding: 0;

      .share-component {
        // height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;

        .preview {
          flex: 1 1 auto;
          overflow-y: auto;
          border-top: 1px solid $gray-2;
          border-bottom: 1px solid $gray-2;
          max-height: 300px;

          .point-container,
          .fact-container,
          .portfolio-card,
          .collection-card {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }

  .modal-header {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 1px 0 0 #ccdae2;
    color: #1286c8;
    text-align: center;
    border: none;
  }

  input:focus {
    outline: none;
  }

  .header {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
  }

  .fa-times {
    color: #b7c7d0;
    font-size: 16px;
  }

  .btn-copy-link {
    // background-color: $av-accent-cyan-light;
    // color: $av-accent-cyan !important;

    &:hover {
      // background-color: #d5e7f4;
    }
  }

  .btn-facebook {
    // background-color: #475993;
    // color: $av-white !important;

    &:hover {
      // background-color: #324584;
    }
  }

  .btn-twitter {
    // background-color: #e6f5ff;
    // color: #1da1f2 !important;

    &:hover {
      // background-color: #438fbe;
    }
  }

  .btn-email {
    // background-color: #fff1f4;
    // color: $av-white !important;

    &:hover {
      // background-color: #5c7fcf;
    }
  }

  .btn-av {
    // background-color: #e9f7ff;
  }

  .btn-copy-link,
  .btn-facebook,
  .btn-twitter,
  .btn-email,
  .btn-av {
    background-color: $av-white;
    border-radius: 4px;
    height: 48px;
    // width: 48px !important;
    padding-left: 80px !important;

    .icon {
      margin-right: 0;

      &.icon-twitter {
        path {
          fill: #1da1f2;
        }
      }

      &.icon-email {
        path {
          fill: #d63552;
        }
      }
    }
  }

  .privacy {
    .btn {
      border: 2px solid $av-blue !important;
      border-radius: 4px;
      height: 33px;
    }

    .not-selected {
      .btn {
        border-color: transparent !important;
        color: $av-gray;
        background-color: $gray-1;
      }
    }
  }

  .btn-submit {
    background: linear-gradient(
      288.08deg,
      #1286c8 0%,
      #2bb5e0 99.25%
    ) !important;
    border: none;
  }
}
