@import 'css/_variables.scss';

.badge-details-modal {
  .modal-body {
    padding: 3rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 1.75rem;
        font-weight: 500;
        color: $av-blue;
      }
    }

    .text {
      margin: 1rem 0;

      .link {
        color: $av-blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .image {
      display: flex;
      justify-content: center;
      margin: 1.5rem 0;
    }
  }
}
