@import 'css/_variables.scss';

.welcome-modal {
  .modal-body {
    padding: 0;

    .btn-close {
      position: absolute;
      right: 0;
      top: 47px;
    }

    .back-to-index {
      position: absolute;
      left: 48px;
      top: 20px;
      color: $gray-3;
      font-size: 12px;
    }
  }
}
